import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../Styles/aboutStyle.js";

const useStyles = makeStyles(styles);

export default function AboutSection() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.section, classes.container)}>
      <h2>
        Atuar como um agente facilitador entre a sociedade civil, fabricantes, e
        instituições governamentais
      </h2>
      <p>
        Fundada no ano de 2020, a Associação Brasileira de Microrredes (ABMR), é
        pessoa jurídica de direito privado sem fins lucrativos e desenvolve
        pesquisas básica e aplicada de caráter científico e tecnológico. A ABMR
        coordena, representa e defende os interesses de seus associados quanto
        ao tema microrredes no Brasil.
      </p>
    </div>
  );
}
