import React, { Component } from "react";
import classNames from "classnames";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import styles from "../Styles/valuesStyle";

class ValuesSection extends Component {
  constructor() {
    super();
    this.state = {
      visible: false
    };
  }

  handleScroll() {
    if (document.documentElement.scrollTop > 430) {
      this.setState({
        visible: true
      });
    } else {
      this.setState({
        visible: false
      });
    }
  }

  componentDidMount() {
    window.onscroll = () => this.handleScroll();
  }

  render() {
    const { visible } = this.state;
    const { classes } = this.props;

    return (
      <>
        <Grid
          container
          className={classNames(
            classes.container,
            classes.section,
            visible ? classes.visible : classes.notVisible
          )}
        >
          <Grid
            item
            xs={12}
            md={4}
            className={classNames(classes.gridItem, classes.missao)}
          >
            {/* <Card className={classNames(classes.card, classes.missao)}>
            <CardBody> */}
            <h3>Missão</h3>
            <p>
              Promover o avanço tecnológico da inserção de Microrredes na matriz
              elétrica nacional, fomentando a competitividade, a consolidação e
              a sustentabilidade do mercado de Microrredes no Brasil.
            </p>
            {/* </CardBody>
          </Card> */}
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            className={classNames(classes.gridItem, classes.visao)}
          >
            {/* <Card className={classNames(classes.card, classes.visao)}>
            <CardBody> */}
            <h3>Visão</h3>
            <p>
              Ser reconhecida como a associação que representa de forma
              legítima, ética e transparente a cadeia produtiva da indústria de
              Microrredes do Brasil.
            </p>
            {/* </CardBody>
          </Card> */}
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            className={classNames(classes.gridItem, classes.valores)}
          >
            {/* <Card className={classNames(classes.card, classes.valores)}>
            <CardBody> */}
            <h3>Valores</h3>
            <ul>
              <li>Qualidade, ética e respeito à legislação;</li>
              <li>Responsabilidade socioambiental;</li>
              <li>Sustentabilidade;</li>
              <li>Transparência;</li>
              <li>Cooperação com todos os integrantes da cadeia produtiva.</li>
            </ul>
            {/* </CardBody>
          </Card> */}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(ValuesSection);
