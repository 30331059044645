import React from "react";
import RoomIcon from "@material-ui/icons/Room";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

function IconSubtitle(props) {
  const { title, color } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <RoomIcon style={{ color: `${color}`, fontSize: 14 }} />
      <Typography variant="body2">{title}</Typography>
    </div>
  );
}

IconSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default IconSubtitle;
