import { container, primaryColor } from "assets/jss/material-kit-react.js";

const footerStyle = theme => ({
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "103px 0 55px",
    display: "flex",
    zIndex: "2",
    position: "relative",

    "& p": {
      fontSize: 14,
      color: "#718090",
      lineHeight: 2
    }
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
    paddingRight: 20,
    transition: ".3s",
    "&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  footerBackground: {
    background: "#17172d"
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  footerInfoBox: {
    marginBottom: 30
  },
  FIBIcon: {
    float: "left",
    width: 48,
    paddingTop: 8,

    "& img": {
      width: 34,
      height: 34
    }
  },
  FIBText: {
    overflow: "hidden",

    "& p": {
      marginBottom: 0
    }
  },
  contactTitle: {
    fontSize: 22,
    fontWeight: 300,
    color: "#fff",
    marginBottom: 35,
    marginTop: 0
  },
  footerBottom: {
    background: "#03031b",
    height: 40,
    color: "#718090",
    display: "flex",
    alignItems: "center"
  },
  contentPosition: {
    justifyContent: "space-between"
  },
  spacing: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 30
    },

    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: 50
    }
  },
  socialWrapper: {
    display: "flex",
    width: "100%"
  }
});
export default footerStyle;
