import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

import HomePage from "views/HomePage/HomePage.js";
import SobreNosPage from "views/SobreNosPage/SobreNosPage.js";
import EstruturaInternaPage from "views/EstruturaInternaPage/EstruturaInternaPage";
import AssociadosPage from "views/AssociadosPage/AssociadosPage.js";
import AssocieSePage from "views/AssocieSePage/AssocieSePage.js";
import BeneficiosPage from "views/BeneficiosPage/BeneficiosPage.js";
import NoticiasPage from "views/NoticiasPage/NoticiasPage.js";
import NoticiaPage from "views/NoticiaPage/NoticiaPage.js";
import EventosPage from "views/EventosPage/EventosPage.js";
import PublicaçoesPage from "views/PublicaçoesPage/PublicaçoesPage.js";
import ArtigosPage from "views/ArtigosPage/ArtigosPage.js";
import ProjetosPage from "views/ProjetosPage/ProjetosPage.js";
import MapPage from "views/Map/MapPage.js";
import ContatoPage from "views/ContatoPage/ContatoPage";
import { PrivateRoute } from "./common/security/privateroutes";

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

export default class App extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/sobre-nos" component={SobreNosPage} />
          <Route path="/estrutura-interna" component={EstruturaInternaPage} />
          <Route path="/associados" component={AssociadosPage} />
          <Route path="/associe-se" component={AssocieSePage} />
          <Route path="/beneficios" component={BeneficiosPage} />
          {/* Página com feed das Notícias */}
          <Route exact path="/noticias" component={NoticiasPage} />
          {/* Página de uma Notícia */}
          <Route path="/noticias/:id" component={NoticiaPage} />
          <Route path="/eventos" component={EventosPage} />
          <Route path="/publicaçoes" component={PublicaçoesPage} />
          <Route path="/mapa" component={MapPage} />
          <PrivateRoute path="/projetos" component={ProjetosPage} />
          <Route path="/artigos" component={ArtigosPage} />
          <Route path="/contato" component={ContatoPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}
