import React, { useState } from "react";

import { newsList } from "../../../assets/newsList";
import newsFeedStyle from "../Styles/newsFeedStyle";
import { Pagination } from "@material-ui/lab";
import NewsCard from "./NewsCard";
import { Grid } from "@material-ui/core";

export default function NewsFeed() {
  const classes = newsFeedStyle();

  const [news] = useState(newsList);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className={classes.section}>
      <div className={classes.content}>
        <Grid container spacing={3}>
          {news.map(newsItem => (
            <Grid item key={newsItem.id} lg={4} md={6} xs={12}>
              <NewsCard newsItem={newsItem} />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.center}>
        <Pagination
          count={1}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
        />
      </div>
    </div>
  );
}
