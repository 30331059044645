import { container } from "assets/jss/material-kit-react.js";

const contactStyle = theme => ({
  container,
  marginLeft: {
    marginLeft: "auto"
  },
  marginRight: {
    marginRight: "auto"
  },
  section: {
    padding: "70px 15px 90px",

    [theme.breakpoints.down("sm")]: {
      paddingTop: 50
    },

    "& h3": {
      color: "#444",
      fontWeight: 400,
      position: "relative",
      marginBottom: 25,

      "&::after": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-10px",
        borderBottom: "7px solid transparent",
        borderImage:
          "linear-gradient(60deg, rgba(114,181,69,1), rgba(16,138,134,1))",
        borderImageSlice: 1,
        width: 60
      }
    },

    "& p": {
      color: "#718090",
      fontSize: 18
    }
  },
  infoWrapper: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 50
    }
  },
  infoBox: {
    marginBottom: 30,
    display: "flex",
    alignItems: "center"
  },
  icon: {
    float: "left",
    paddingTop: 8,
    margin: "0 25px 0 15px",

    "& img": {
      width: 55,
      height: 55
    }
  },
  text: {
    overflow: "hidden",

    "& p": {
      marginBottom: 0
    }
  }
});

export default contactStyle;
