const handleDaily = require("./handleDaily");
const handleDateOfMonth = require("./handleDateOfMonth");
const handleDayOfMonth = require("./handleDayOfMonth");
const handleWeekly = require("./handleWeekly");

module.exports = {
  handleDaily,
  handleDateOfMonth,
  handleDayOfMonth,
  handleWeekly
};
