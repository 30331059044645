import React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(() => ({
  content: {
    "& p": {
      textAlign: "justify"
    }
  }
}));

export default function TUmodal(props) {
  const { open, handleClose } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Potítica de Privacidade"}
          <p>Última atualização em 06/10/2020</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.content}
          >
            <h3 style={{ marginTop: "0" }}>Introdução</h3>
            <p>
              A presente Política de Privacidade estabelece de forma simples e
              transparente como o site microrredes.org.br (“Plataforma”) trata
              os dados pessoais, considerando todas as questões legais e
              regulamentadoras. Recomendamos a leitura atenta.
              <br />
              <br />
              Prezamos que a coleta da informação seja feita de forma
              transparente ao titular do dado e que o uso das informações seja
              pautado pela responsabilidade.
            </p>
            <h3>Informações coletadas</h3>
            <p>
              Há dois tipos principais de informações que são coletadas pela
              Plataforma:
              <br />- <strong>Dados informados pelo usuário</strong> são aqueles
              que a própria pessoa comunica ao efetuar o cadastro em nossa
              plataforma, como nome, e-mail, contato, endereço, documento de
              identificação e áreas de interesse.
              <br />- <strong>Dados de navegação</strong> são informações
              coletadas quando um usuário navega em nosso site, por exemplo,
              informações sobre o navegador do dispositivo usado e dados de
              cookies. Um cookie é uma porção de informações que um site
              armazena temporariamente no computador do visitante para lhe
              fornecer uma melhor experiência com o site visitado.
            </p>
            <h3>Uso das informações coletadas</h3>
            <p>
              Dados de navegação são coletados para adequar a comunicação do
              Plataforma ao seu perfil. Essa informação não é publicada e usada
              para nenhuma outra finalidade. O usuário pode, a qualquer momento,
              bloquear a coleta automática de cookies através do seu navegador.
              Nesse caso, é possível que algumas das funções oferecidas pela
              Plataforma deixem de funcionar corretamente.
              <br />
              <br />
              Ferramentas de analytics também poderão ser empregadas para a
              análise de dados de navegação, tais como quantidade e quais
              páginas foram visitadas. A utilização dessas ferramentas sempre
              respeitará os termos desta Política de Privacidade.
              <br />
              <br />
              Dados informados pelo usuário são coletados para identificar e
              autenticar um usuário quando ele utiliza a Plataforma; para
              garantir um canal de comunicação com o usuário disponibilizando
              informações sobre o produto adquirido, via e-mail e apenas quando
              necessário; assim como para fornecer soluções personalizadas de
              acordo com os assuntos de interesse informados pelo usuário.
              <br />
              <br />É importante lembrar que a Plataforma pode conter links para
              outras páginas, que possuem Política de Privacidade com previsões
              diversas do disposto neste documento. Dessa forma, a Plataforma
              não se responsabiliza pela coleta, utilização, compartilhamento e
              armazenamento de dados dos usuários pelos responsáveis por tais
              páginas de terceiros.
            </p>
            <h3>Controle de privacidade</h3>
            <p>
              As suas informações estão protegidas contra o uso inadequado ou
              divulgação imprópria. Nós não compramos listas de e-mails ou
              informações comerciais, nem vendemos ou repassamos informações
              sobre os nossos visitantes.
            </p>
            <h3>Compartilhamento de informações</h3>
            <p>
              Alguns serviços utilizados pela Plataforma demandam atendimento
              especializado em outros segmentos, podendo haver o
              compartilhamento das informações coletadas por meio da Plataforma.
              Tais serviços incluem, mas não estão limitados à estrutura de
              datacenter, serviço para emissão de notas fiscais eletrônicas, e
              serviços para recebimento de pagamentos.
              <br />
              <br />
              Podemos também divulgar seus dados a terceiros:
              <br />- Quando exigido por lei;
              <br />- Para responder a uma ordem judicial ou administrativa
              válida;
              <br />- Como prova, em processo judicial ou administrativo;
              <br />- Para cumprimento de obrigação legal ou regulatória;
              <br />- Para proteger nossos clientes, manter a segurança de
              produtos e serviços e proteger os direitos ou a propriedade da
              Plataforma e de seus clientes.
            </p>
            <h3>Armazenamento, retenção e exclusão de informações</h3>
            <p>
              As informações dos usuários serão armazenadas em servidores
              contratados pela Plataforma.
              <br />
              <br />
              As informações coletadas são retidas pela Plataforma até que elas
              não sejam mais necessárias para o cumprimento do objetivo para o
              qual foram coletadas, inclusive para garantir a conformidade com
              obrigações jurídicas e auditorias.
              <br />
              <br />
              A qualquer momento, se você desejar, é possível solicitar acesso,
              atualização ou exclusão de suas informações armazenadas em nosso
              sistema. A atualização dos dados pode ser realizada diretamente
              pela Plataforma. Já a solicitação de exclusão dos dados deve ser
              formalizada através do e-mail suporte@microrredes.org.br,
              resguardando o direito da Plataforma de manter as informações
              necessárias para o cumprimento de contratos e da lei.
              <br />
              <br />
              Caso você tenha fornecido o seu consentimento para tratamento dos
              seus dados pessoais, você pode solicitar a revogação desta
              autorização através do e-mail suporte@microrredes.org.br. A
              revogação do consentimento pode resultar na impossibilidade de uso
              das funcionalidades e serviços prestados pela Plataforma.
            </p>
            <h3>Segurança</h3>
            <p>
              Esforços empregados pela Plataforma para garantir a segurança dos
              sistemas na guarda de seus dados são descritos a seguir:
              <br />
              - Autorização de acesso somente a pessoas previamente
              estabelecidas aos locais onde armazenamos as informações;
              <br />
              - Aqueles que entrarem em contato com as informações deverão se
              comprometer a manter sigilo absoluto;
              <br />- A quebra do sigilo acarretará responsabilidade civil e o
              responsável será processado nos moldes da legislação brasileira.
              <br />
              <br />
              Esta Política representa o esforço da Plataforma em resguardar as
              informações dos usuários que utilizam seus produtos e serviços. No
              entanto, em razão da própria natureza da Internet, não é possível
              garantir que terceiros mal intencionados não obtenham sucesso em
              acessar indevidamente as informações armazenadas.
              <br />
              <br />A senha do usuário é uma parte importante de nosso sistema
              de segurança, e é responsabilidade do usuário protegê-la. Não
              compartilhe sua senha com terceiros. Em caso de suspeita de
              violação da senha ou da conta, altere-a imediatamente ou entre em
              contato com suporte@microrredes.org.br para sanar a situação.
            </p>
            <h3>Consentimento do usuário</h3>
            <p>
              Esta Política de Privacidade se aplica ao site microrredes.org.br
              e a recursos vinculados a ele.
              <br />
              <br />
              A aceitação desta Política de Privacidade pode ocorrer duas
              formas: no ato do seu clique no botão “Aceito” ao navegar na
              Plataforma ou ao marcar a opção “Declaro concordar com os Termos
              de uso e Política de Privacidade” durante o cadastro de suas
              informações na Plataforma. Em ambos os casos, o usuário concorda e
              permite o acesso às suas informações a partir de seu aceite,
              manifestando consentimento livre, expresso e informado. Se o
              usuário não concordar com a política de tratamento de informações
              descrita neste documento, não deve utilizar a Plataforma.
              <br />
              <br />
              Ao aceitar os termos desta Política de Privacidade, você concorda
              expressamente em fornecer apenas dados pessoais verdadeiros,
              atuais e precisos. Você será o único responsável pelas informações
              falsas, desatualizadas ou imprecisas que fornecer diretamente à
              Plataforma.
              <br />
              <br />É importante ressaltar que o tratamento de seus dados é
              condição necessária para que possamos prestar nossos serviços a
              você. Em caso de dúvidas, entre contato através do e-mail
              suporte@microrredes.org.br.
            </p>
            <h3>Alterações da política</h3>
            <p>
              A Plataforma se reserva no direito de alterar os termos desta
              política a qualquer momento, buscando sua melhoria contínua.
              <br />
              <br />
              Incentivamos que você revise periodicamente esta política para
              entender como estamos usando e protegendo seus dados pessoais.
              Indicamos sempre a data em que as últimas alterações foram
              publicadas. Nós não reduziremos seus direitos nesta Política de
              Privacidade sem seu consentimento explícito. Se as alterações
              forem significativas, forneceremos um aviso com mais destaque, o
              que inclui, no caso de alguns serviços, notificação por e-mail das
              alterações da Política de Privacidade.
              <br />
              <br />
              Ao continuar utilizando a Plataforma após uma alteração na
              Política de Privacidade, você estará concordando com as novas
              condições. Em caso de discordância, entre em contato conosco
              através do e-mail suporte@microrredes.org.br.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
