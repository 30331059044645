import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../Styles/categoriesStyle";

const useStyles = makeStyles(styles);

export default function CategoriesSection() {
  const formRef = useRef(null);
  const scrollToForm = () =>
    window.scrollTo({
      behavior: "smooth",
      top: formRef.current.offsetTop + formRef.current.offsetHeight - 100
    });

  useEffect(() => {
    if (formRef.current) {
      formRef.current.focus();
    }
  }, []);

  const Button = () => (
    <div
      onClick={scrollToForm}
      className="pricingTable-firstTable_table__getstart"
    >
      Associe-se
    </div>
  );

  const classes = useStyles();

  const [option, setOption] = useState(1);
  const optionDetails = [
    { amountOfWorkers: "até 20", price: "200" },
    { amountOfWorkers: "21 até 50", price: "500" },
    { amountOfWorkers: "mais de 51", price: "900" }
  ];

  return (
    <>
      <div className={classes.background} ref={formRef}>
        <div className={classNames(classes.container, classes.section)}>
          <div className="pricingTable">
            <h2 className="pricingTable-title">Categorias de Associação</h2>
            <h3 className="pricingTable-subtitle">Conheça as opções</h3>

            <ul className="pricingTable-firstTable">
              <li className="pricingTable-firstTable_table">
                <h1 className="pricingTable-firstTable_table__header">
                  Associado Colaborador
                </h1>
                <p className="pricingTable-firstTable_table__pricing">
                  <span>R$</span>
                  <span>35</span>
                  <span>,00</span>
                  <span>mês</span>
                </p>
                <ul className="pricingTable-firstTable_table__options">
                  <li>Networking empresarial</li>
                  <li>Acesso ao conteúdo exclusivo no site</li>
                  <li>Acesso ao radar de negócios</li>
                  <li>Participação nos grupo de trabalhos</li>
                  <li>Desconto nos eventos promovidos</li>
                </ul>
                <Button />
              </li>
              <li className="pricingTable-firstTable_table">
                <h1 className="pricingTable-firstTable_table__header">
                  Associado Pleno {"I".repeat(option)}
                </h1>
                <p className="pricingTable-firstTable_table__pricing">
                  <span>R$</span>
                  <span>{optionDetails[option - 1].price}</span>
                  <span>,00</span>
                  <span>mês</span>
                </p>
                <label
                  className="pricingTable-firstTable_table__label"
                  htmlFor="quantidade-colaboradores"
                >
                  Colaboradores na instituição:
                </label>
                <select
                  className="pricingTable-firstTable_table__select"
                  id="quantidade-colaboradores"
                  value={option}
                  onChange={event => setOption(event.target.value)}
                >
                  {optionDetails.map((details, index) => (
                    <option
                      defaultValue={index === 0}
                      value={index + 1}
                      key={index}
                    >
                      {details.amountOfWorkers}
                    </option>
                  ))}
                </select>
                <ul className="pricingTable-firstTable_table__options">
                  <li>Networking empresarial</li>
                  <li>Acesso ao conteúdo exclusivo no site</li>
                  <li>Acesso ao radar de negócios</li>
                  <li>Participação nos grupo de trabalhos</li>
                  <li>Desconto nos eventos promovidos</li>
                  <li>Exposição da marca como associado</li>
                  <li>Divulgar no mural de oportunidade</li>
                  <li>Possui direito a voto nas assembleias</li>
                  <li>Pode candidatar-se a cargos eletivos</li>
                </ul>
                <Button />
              </li>
              <li className="pricingTable-firstTable_table">
                <h1 className="pricingTable-firstTable_table__header">
                  Associado Apoiador
                </h1>
                <p className="pricingTable-firstTable_table__pricing">
                  <span>R$</span>
                  <span>0</span>
                  <span>,00</span>
                  <span>mês</span>
                </p>
                <ul className="pricingTable-firstTable_table__options">
                  <li>Networking empresarial</li>
                  <li>Acesso ao conteúdo exclusivo no site</li>
                  <li>Acesso ao radar de negócios</li>
                  <li>Exposição da marca como associado</li>
                  <li>Mensalidade isenta mediante contrapartida</li>
                </ul>
                <Button />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
