import React from "react";
import classNames from "classnames";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import FormSection from "./Sections/FormSection";
import CategoriesSection from "./Sections/CategoriesSection";

import imgPath from "assets/img/solar-panels.png";

import { makeStyles } from "@material-ui/core/styles";
import { container } from "assets/jss/material-kit-react.js";

const useStyles = makeStyles({
  container,
  section: {
    padding: "50px 20px",

    "& h2": {
      color: "#081624",
      marginBottom: 30,
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif"
    },

    "& h3": {
      color: "#081624",
      marginTop: 30,
      marginBottom: 15,
      fontWeight: 500,
      fontFamily: "Open Sans, sans-serif"
    },

    "& p, & li": {
      color: "#666",
      fontSize: 16,
      lineHeight: 1.5
    }
  }
});

export default function AssocieSePage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection title="Associe-se" imgPath={imgPath} size="cover" />

      <div className={classNames(classes.container, classes.section)}>
        <h2>Seja um associado</h2>
        <p>
          Em busca de ser uma referência como associação de Microrredes, estamos
          sempre em busca de oferecer melhores serviços e benefícios para os
          nossos associados. Havendo interesse em obter mais detalhes sobre
          esses benefícios entre em contato conosco pelo telefone: (45) 3576
          7113 ou contato@microrredes.org.br , de segunda a sexta-feira das 08h
          às 18h.
        </p>
      </div>

      <CategoriesSection />

      <FormSection />

      <Footer />
    </div>
  );
}
