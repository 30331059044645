import React from "react";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import ContactInfoSection from "./Sections/ContactInfoSection.js";
import MapSection from "./Sections/MapSection.js";

import imgPath from "assets/img/windmill.jpg";

export default function ContatoPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection
        title="Contato"
        imgPath={imgPath}
        size="cover"
        position="bottom"
      />
      <ContactInfoSection />
      <MapSection />

      <Footer />
    </div>
  );
}
