import { container } from "assets/jss/material-kit-react.js";

const bannerStyle = theme => ({
  container,
  section: {
    display: "flex",
    alignItems: "top",
    paddingTop: 200,
    paddingLeft: 50,
    height: "90%",
    "& h1": {
      color: "#fff",
      fontWeight: 300,
      fontSize: "4rem",
      lineHeight: 1.2,
      [theme.breakpoints.down("sm")]: {
        fontSize: "3rem"
      },
      "& span": {
        paddingLeft: 25,
        paddingBottom: 15,
        display: "inline-block",
        background: "#72b545",
        color: "#081624",

        "&:last-child": {
          paddingRight: 30,
          color: "#fff",
          fontWeight: 500
        }
      }
    },

    "& p": {
      color: "#fff",
      fontSize: 18,
      lineHeight: 2
    }
  },
  wrapper: {
    color: "#FFFFFF",
    textAlign: "left",
    width: "100%"
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0"
  },
  button: {
    padding: "23px 47px",
    marginRight: 25,
    border: "none",
    borderRadius: "none",
    display: "inline-block",
    fontSize: "14px",
    fontWeight: 600,
    minWidth: "186px",
    textTransform: "uppercase",
    lineHeight: "normal",
    cursor: "pointer",
    textAlign: "center",
    marginBottom: "10px"
  },
  studies: {
    color: "#fff",
    background: "#081624",
    "&:hover": {
      background: "#050d15"
    }
  },
  seeMore: {
    color: "#081624",
    background: "#fff",
    "&:hover": {
      background: "#f1f1f1"
    }
  },
  overlayWrapper: {
    position: "relative"
  },
  overlay: {
    position: "absolute" /* Sit on top of the page content */,
    width: "100%" /* Full width (cover the whole page) */,
    height: "100%" /* Full height (cover the whole page) */,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.15)" /* Black background with opacity */
  }
});

export default bannerStyle;
