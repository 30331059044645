import { container } from "assets/jss/material-kit-react.js";

const calendarStyle = theme => ({
  container,
  section: {
    textAlign: "center",
    padding: "80px 0 150px",

    [theme.breakpoints.down("sm")]: {
      padding: "70px 15px"
    },

    "& p": {
      color: "#718090",
      fontSize: 18,
      lineHeight: 2
    },

    "& a h2": {
      textShadow: "1px 3px 3px rgba(0,0,0,.5)",
      marginBottom: 40
    },

    "& a h4": {
      textDecoration: "none",
      display: "inline-block",
      padding: "15px 40px",
      borderRadius: 4,
      background: "#72b545",
      color: "#fff",
      fontWeight: 600,
      boxShadow: "1px 3px 3px rgba(0,0,0,.3)",
      transition: "all .2s"
    },
    "& a h4:hover": {
      background: "#333"
    }
  }
});

export default calendarStyle;
