import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Container,
  Collapse
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Logo from "assets/img/logo-apple.png";

import useStyles from "./styles";
import authUser from "../../common/api/users/user";
import { setToken } from "../../common/security/token";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "obrigatório" },
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: "obrigatório" },
    length: {
      maximum: 128
    }
  }
};

const SignIn = props => {
  const { history } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleLogin = event => {
    event.preventDefault();
    const formStateValues = {
      username: formState.values.email,
      password: formState.values.password
    };
    authUser(formStateValues)
      .then(response => {
        setToken(response.data.token);
        history.push("/");
      })
      .catch(err => new Error(setOpen(true), err));
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.wrapper}>
        <img src={Logo} alt="Logo" height={50} style={{ marginBottom: 10 }} />
        <Typography component="h1" variant="h5">
          Faça seu login
        </Typography>
        <Collapse in={open} className={classes.alert}>
          <Alert severity="warning">
            <AlertTitle>Usuário não cadastrado</AlertTitle>
            Verifique se o e-mail e senha estão corretos
          </Alert>
        </Collapse>
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            id="emailSignIn"
            label="E-mail"
            name="email"
            autoComplete="email"
            type="email"
            required
            fullWidth
            autoFocus
            className={classes.textField}
            error={hasError("email")}
            helperText={hasError("email") ? formState.errors.email[0] : null}
            onChange={handleChange}
            value={formState.values.email || ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            id="password"
            label="Senha"
            name="password"
            autoComplete="current-password"
            type="password"
            required
            fullWidth
            className={classes.textField}
            error={hasError("password")}
            helperText={
              hasError("password") ? formState.errors.password[0] : null
            }
            onChange={handleChange}
            value={formState.values.password || ""}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Permanecer conectado"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.loginButton}
            disabled={!formState.isValid}
          >
            Entrar
          </Button>
        </form>
      </div>
    </Container>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
