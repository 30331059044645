import React from "react";
import PropTypes from "prop-types";
// import moment from "moment";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import useStyles from "../Styles/newsCardStyles";
import { Link } from "react-router-dom";

const NewsCard = props => {
  const { newsItem } = props;

  const classes = useStyles();

  // const calcTimeDiff = time => {
  //   return moment().diff(newsItem.updatedAt, time);
  // };

  // const displayTime = time => {
  //   const timeValue = calcTimeDiff(time);
  //   const timeType = time === "hours" ? "hora" : "dia";

  //   return `${timeValue} ${timeType}${timeValue > 1 ? "s" : ""}`;
  // };

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.imageContainer}>
          {newsItem.img ? (
            <img alt="newsItem" className={classes.image} src={newsItem.img} />
          ) : (
            <img
              alt="newsItem"
              className={classes.image}
              src="https://images.pexels.com/photos/159397/solar-panel-array-power-sun-electricity-159397.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            />
          )}
        </div>
        <Typography className={classes.title} gutterBottom variant="h4">
          {newsItem.title}
        </Typography>
        <Typography className={classes.description} variant="body1">
          {newsItem.description}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions style={{ justifyContent: "center" }}>
        <Link
          to={`/noticias/${newsItem.id}`}
          className={classes.button}
          key={newsItem.id}
        >
          Ver mais
        </Link>
      </CardActions>
      <CardActions>
        <Grid container justify="space-between">
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} />
            <Typography display="inline" variant="body2">
              Publicado em {newsItem.publishedAt}
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

NewsCard.propTypes = {
  newsItem: PropTypes.object.isRequired
};

export default NewsCard;
