import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import GroupIcon from "@material-ui/icons/Group";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
// core components
import { Grid } from "@material-ui/core";
import IconBlock from "components/IconBlock/IconBlock.js";

import styles from "../Styles/introductionStyle.js";

const useStyles = makeStyles(styles);

export default function IntroductionSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4}>
          <IconBlock
            title="Quem somos"
            description="Temos o propósito de congregar as empresas de toda a cadeia produtiva do setor de Microrredes em operação no Brasil."
            icon={InfoOutlined}
            iconColor="info"
            vertical
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <a href="/associe-se">
            <IconBlock
              title="Associe-se"
              description="Ao se tornar um associado, você terá acesso a diversas informações sobre o mercado de microrredes. Além disso integrará uma rede com pesquisadores e profissionais renomados."
              icon={GroupIcon}
              iconColor="success"
              vertical
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <IconBlock
            title="Publicações"
            description="Acesso ao conteúdo exclusivo preparado para quem é um apaixonado pelo tema microrredes. "
            icon={DescriptionOutlined}
            iconColor="danger"
            vertical
          />
        </Grid>
      </Grid>
    </div>
  );
}
