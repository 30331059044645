import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import { Button, Dialog, IconButton, Typography } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import CloseIcon from "@material-ui/icons/Close";

import styles from "../Styles/customEventStyle.js";

const diaDaSemana = [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado"
];

const mes = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
];

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classNames(classes.content, classes.root)}
      {...other}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class CustomEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.getEventTime = this.getEventTime.bind(this);
  }

  handleClick = () => {
    this.state.open
      ? this.setState({ open: false })
      : this.setState({ open: true });
  };

  getEventTime = (start, end) => {
    return (
      diaDaSemana[start.getDay()] +
      ", " +
      start.getDate() +
      " de " +
      mes[start.getMonth()] +
      " — " +
      start.getHours() +
      ":" +
      (start.getMinutes() < 10 ? "0" : "") +
      start.getMinutes() +
      " às " +
      end.getHours() +
      ":" +
      (end.getMinutes() < 10 ? "0" : "") +
      end.getMinutes()
    );
  };

  render() {
    const { classes } = this.props;
    const {
      title,
      description,
      start,
      end,
      location,
      glink
    } = this.props.event;
    const { open } = this.state;
    console.log(this.props);
    console.log(this.props.event.gLink);

    return (
      <>
        <div onClick={this.handleClick}>{title}</div>
        <Dialog
          onClose={this.handleClick}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClick}>
            {title}
          </DialogTitle>
          <DialogContent className={classes.content} dividers>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>
              {this.getEventTime(start, end)}
            </Typography>

            {location ? (
              <div className={classes.wrapper}>
                <Typography
                  variant="button"
                  color="textSecondary"
                  display="block"
                  gutterBottom
                >
                  Local:
                </Typography>
                <Typography gutterBottom>{location}</Typography>
              </div>
            ) : (
              ""
            )}

            {description ? (
              <div className={classes.wrapper}>
                <Typography
                  variant="button"
                  color="textSecondary"
                  display="block"
                  gutterBottom
                >
                  Descrição:
                </Typography>
                <Typography gutterBottom>{description}</Typography>
              </div>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              href={glink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.handleClick}
              color="primary"
            >
              Salvar no Google Agenda
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(CustomEvent);
