import React from "react";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import CalendarSection from "./Sections/CalendarSection";

import imgPath from "assets/img/solar-panels-2.jpg";

const calendar_configuration = {
  api_key: "AIzaSyDQqJkvkXazO9VYfHOpmpLVhQPqXn0aILw",
  calendars: [
    {
      name: "Microrredes",
      url: "5vgl4bs1tkmu38tbm24nhdkg6c@group.calendar.google.com"
    }
  ],
  dailyRecurrence: 700,
  weeklyRecurrence: 500,
  monthlyRecurrence: 20
};

export default function EventosPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection
        title="Eventos"
        imgPath={imgPath}
        size="cover"
        position="center"
      />

      <CalendarSection config={calendar_configuration} />

      <Footer />
    </div>
  );
}
