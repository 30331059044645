import { container } from "assets/jss/material-kit-react.js";

const valuesStyle = theme => ({
  container,
  section: {
    padding: "50px 20px",

    "& h2": {
      color: "#081624",
      marginBottom: 30,
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif"
    },

    "& h3": {
      color: "#eee",
      fontSize: 50,
      marginTop: 0,
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, sans-serif"
    },

    "& p, & li": {
      color: "#333",
      fontSize: 18,
      lineHeight: 1.5
    },

    "& p": {
      marginLeft: 20
    },

    "& p, & ul": {
      marginTop: "-50px"
    }
  },
  gridItem: {
    borderRight: "1px solid #ccc",
    padding: "20px 40px",

    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #ccc",
      borderRight: "none",
      padding: "50px 40px"
    },

    "&:last-child": {
      borderRight: "none",

      [theme.breakpoints.down("sm")]: {
        borderBottom: "none"
      }
    }
  },
  card: {
    padding: 5,
    transition: "all 200ms ease",

    "&:hover": {
      transform: "translateY(-1px)",
      boxShadow:
        "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    }
  },
  visible: {
    "& h3": {
      color: "#999",
      transition: "all .5s ease-in-out"
    },
    "& p, & ul": {
      marginTop: 0,
      transition: "all .5s ease-in-out"
    }
  },
  notVisible: {
    "& h3": {
      color: "#eee",
      transition: "all .5s ease-in-out"
    },
    "& p, & ul": {
      marginTop: "-50px",
      transition: "all .5s ease-in-out"
    }
  },
  missao: {
    "& h3": {
      transitionDelay: "0"
    },
    "& p, & ul": {
      transitionDelay: "0"
    }
  },
  visao: {
    "& h3": {
      transitionDelay: ".2s"
    },
    "& p, & ul": {
      transitionDelay: ".2s"
    }
  },
  valores: {
    "& h3": {
      transitionDelay: ".4s"
    },
    "& p, & ul": {
      transitionDelay: ".4s"
    }
  }
});

export default valuesStyle;
