import { container } from "assets/jss/material-kit-react.js";

const contactFormStyle = theme => ({
  container,
  section: {
    padding: "50px 30px 25px",

    "& h2": {
      color: "#081624",
      fontWeight: 600,
      marginTop: 0,
      textAlign: "center"
    },

    "& h5": {
      color: "#718090",
      fontSize: 18,
      lineHeight: 1,
      marginBottom: 5,
      textAlign: "center"
    }
  },
  formWrapper: {
    padding: "30px 0 0",

    "& label.Mui-focused": {
      borderColor: "green"
    }
  },
  inputWrapper: {
    backgroundColor: "#fff"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "15px",
    backgroundColor: "#198a86",

    "&:hover": {
      backgroundColor: "#146c69"
    }
  },
  inputTitle: {
    fontFamily: "Roboto, sans-serif",
    color: "#7d7d84",
    fontWeight: 400,
    marginLeft: 15,
    fontSize: 16,
    textTransform: "none"
  },
  background: {
    background: "#f9f9f9",
    borderTop: "5px solid #72b545",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.05), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.05)",
    borderRadius: 6
  }
});

export default contactFormStyle;
