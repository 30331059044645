import { container } from "assets/jss/material-kit-react.js";

const organizationGraphStyle = theme => ({
  container,
  intro: {
    background: "#fff"
  },
  graph: {
    padding: "60px 20px",
    background: "#fbfbfb",
    marginBottom: 60
  },
  section: {
    padding: "60px 20px 30px",

    "& h2": {
      color: "#081624",
      marginBottom: 30,
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif"
    },

    "& p": {
      color: "#666",
      fontSize: 16,
      lineHeight: 2,
      marginBottom: 20
    },

    "& .reactOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle": {
      borderRight: "solid 1px #999"
    },

    "& .reactOrgChart .nodeLineBorderTop": {
      borderTop: "solid 1px #999"
    }
  },
  text: {
    marginBottom: 60
  },
  initechNode: {
    padding: "20px 30px",
    width: 160,
    display: "inline-block",
    border: 0,
    position: "relative",
    fontSize: ".875rem",
    minWidth: 0,
    wordWrap: "break-word",
    background: "#fff",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    borderRadius: 6,
    color: "#777",
    fontWeight: 500,
    transition: "all 200ms ease",
    "&:hover": {
      color: "#555",
      transform: "translateY(-1px)",
      boxShadow:
        "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    }
  },

  tree: {
    "& ul": {
      paddingTop: 20,
      position: "relative",
      transition: "all 0.5s",

      "&  ul::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: "50%",
        borderLeft: "1px solid #ccc",
        width: 0,
        height: 20
      }
    },

    "& li": {
      float: "left",
      textAlign: "center",
      listStyleType: "none",
      position: "relative",
      padding: "20px 5px 0 5px",

      transition: "all 0.5s",

      "& a": {
        border: "1px solid #ccc",
        padding: "5px 10px",
        textDecoration: "none",
        color: "#666",
        fontFamily: "arial, verdana, tahoma",
        fontSize: 11,
        display: "inline-block",

        borderRadius: 5,

        transition: "all 0.5s",

        "&:hover, &:hover+ul li a": {
          background: "#c8e4f8",
          color: "#000",
          border: "1px solid #94a0b4"
        },

        "&hover+ul li::after, &:hover+ul li::before, &:hover+ul::before, :hover+ul ul::before": {
          borderColor: "#94a0b4"
        }
      },

      "&::before, &::after": {
        content: "''",
        position: "absolute",
        top: 0,
        right: "50%",
        borderTop: "1px solid #ccc",
        width: "50%",
        height: 20
      },

      "&::after": {
        right: "auto",
        left: "50%",
        borderLeft: "1px solid #ccc"
      },

      "&:only-child": {
        paddingTop: 0,

        "&::before, &::after": {
          display: "none"
        }
      },

      "&first-child": {
        "&::before, &::after": {
          border: "0 none"
        },

        "&::after": {
          borderRadius: "5px 0 0 0"
        }
      },

      "&:last-child::before": {
        borderRight: "1px solid #ccc",
        borderRadius: "0 5px 0 0"
      }
    }
  }
});

export default organizationGraphStyle;
