import React from "react";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import ProjectsMap from "./Sections/ProjectsMap.jsx";

export default function MapPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <ProjectsMap />

      <Footer />
    </div>
  );
}
