import { container } from "assets/jss/material-kit-react.js";

const newsletterStyle = {
  container,
  root: {
    background: "#72b545"
  },
  section: {
    padding: "40px 15px 33px",
    display: "flex",
    alignItems: "center",
    "& h2": {
      color: "#fff",
      marginBottom: 40
    },

    "& p": {
      color: "#fff",
      fontSize: 18,
      lineHeight: 2
    }
  },
  input: {
    fontFamily: "'Open Sans', 'Roboto', sans-serif",
    padding: "23px 47px",
    border: "none",
    width: "100%"
  },
  inputText: {
    paddingLeft: 20
  },
  submitButton: {
    display: "inline-block",
    fontSize: "14px",
    fontWeight: 600,
    minWidth: "186px",
    textTransform: "uppercase",
    color: "#fff",
    background: "#081624",
    lineHeight: "normal",
    cursor: "pointer",
    textAlign: "center",

    "&:disabled": {
      background: "gray",
      color: "lightgray",
      cursor: "none",
      pointerEvents: "none"
    }
  }
};

export default newsletterStyle;
