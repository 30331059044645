// -- Arrays para os campos de selecionar do form

export const partnersList = [
  {
    id: 1,
    name: "RTDS",
    img:
      "https://www.rtds.com/wp-content/uploads/2020/06/RTDS_logo_navy_cmyk.png",
    contact: {
      phone: "21 2570 2051",
      email: "cayres@hexanet.com.br",
      site: "https://www.rtds.com/"
    }
  },
  {
    id: 2,
    name: "HIOKI",
    img: "https://i.ibb.co/DfFv3Gv/01-logo-Blue-Back.png",
    contact: {
      phone: "+55.11.942.844.654",
      email: "brunom@hiokiusa.com",
      site: "https://hiokiusa.com/"
    }
  },
  {
    id: 2,
    name: "PTI",
    img: "https://www.pti.org.br/sites/default/files/logo-pti_0_0.png",
    contact: {
      phone: "+55 (45) 3576.7200",
      email: "pti@pti.org.br",
      site: "https://www.pti.org.br/"
    }
  },
  {
    id: 3,
    name: "UFPR",
    img:
      "https://www.ufpr.br/portalufpr/wp-content/uploads/2018/11/thumbnail-default.jpg",
    contact: {
      phone: "+55(41) 3360-5000",
      email: "sic@ufpr.br",
      site: "https://www.ufpr.br/portalufpr/"
    }
  },
  {
    id: 4,
    name: "GHM",
    img:
      "http://www.ghmsolutions.com.br/assets/img/Logo%20-%20GHM%20Solutions.png",
    contact: {
      phone: "+55 (11) 99788-2408",
      email: "marketing@ghmsolutions.com.br",
      site: "http://www.ghmsolutions.com.br"
    }
  }
];
