import { container } from "assets/jss/material-kit-react.js";

const partnersStyle = theme => ({
  section: {
    padding: "60px 0 80px 100px",
    background: "#fff",
    textAlign: "center",
    "& h2": {
      color: "#081624",
      fontWeight: 500,
      marginBottom: 40
    }
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  partnersSlider: {
    "& .slick-dots": {
      bottom: "-30px"
    }
  },
  sliderWrapper: {
    marginBottom: "20px",
    "& img": {
      maxWidth: 250,

      [theme.breakpoints.up("xs")]: {
        maxWidth: 130
      },

      [theme.breakpoints.up("sm")]: {
        maxWidth: 150
      }
    }
  }
});

export default partnersStyle;
