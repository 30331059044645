// -- Arrays para os campos de selecionar do form
export const estados = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Distrito Federal",
  "Espírito Santo",
  "Goías",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Pará",
  "Paraíba",
  "Paraná",
  "Pernambuco",
  "Piauí",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rondônia",
  "Roraíma",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins"
];

export const categoriasDeAssociacaoPJ = [
  "Apoiador - R$ 0,00 / Mês",
  "Pleno I - R$ 200,00 / Mês",
  "Pleno II - R$ 500,00 / Mês",
  "Pleno III - R$ 900,00 / Mês"
];

export const categoriasDeAssociacaoPF = ["Colaborador - R$ 35,00 / Mês"];

export const meiosDeCobranca = [
  {
    pt_br: "Boletos mensais enviados por e-mail (vencimento dia 10)",
    us: "MONTHLY"
  },
  {
    pt_br: "Boleto trimestral (com 5% de desconto)",
    us: "QUARTERLY"
  },
  {
    pt_br: "Boleto semestral (com 8% de desconto)",
    us: "SEMESTRAL"
  },
  {
    pt_br: "Boleto anual (com 10% de desconto)",
    us: "YEARLY"
  },
  {
    pt_br: "Pagamento via PICPAY",
    us: "PICPAY"
  }
];

export const tempoDeAtuacao = [
  {
    pt_br: "Mais de 10 anos",
    us: "MORE10YEARS"
  },
  {
    pt_br: "De 6 a 10 anos",
    us: "UP6TO10YEARS"
  },
  {
    pt_br: "De 2 a 5 anos",
    us: "UP2TO5YEARS"
  },
  {
    pt_br: "Menos de 2 anos",
    us: "LESS2YEARS"
  }
];

export const itensConhecimento = [
  {
    name: "inadimplencia",
    text:
      "Estou ciente e concordo que em caso de inadimplência por mais de 60 dias, o boleto é automaticamente enviado a protesto pelo banco."
  },

  {
    name: "boletos",
    text:
      "Estou ciente que receberei os boletos de pagamento por email (vencimento dia 10) e que a cobrança apenas cessará após solicitação de desassociação por email."
  },

  {
    name: "estatuto",
    text:
      "Tenho conhecimento que o Estatuto Social da ABMR está disponível no site"
  },

  {
    name: "atuacao",
    text:
      "Minha empresa atua direta ou indiretamente em Geração Distribuída com fontes renováveis de energia."
  },

  {
    name: "lgpd",
    text:
      "Eu concordo em receber comunicações e ofertas personalizadas de acordo com meus interesses."
  }
];
// -- Fim dos arrays do form
