import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  mapSizing: {
    height: 600,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      height: 400
    }
  }
}));

export default function GoogleMap({ children, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.mapSizing}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDQqJkvkXazO9VYfHOpmpLVhQPqXn0aILw"
        }}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
}

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

GoogleMap.defaultProps = {
  children: null
};
