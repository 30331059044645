import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import OrgChart from "react-orgchart";
import "react-orgchart/index.css";

import styles from "../Styles/organizationGraphStyle";

class OrganizationGraph extends Component {
  render() {
    const { classes } = this.props;

    const initechOrg = {
      name: "Assembleia Geral",
      children: [
        {
          name: "Conselho Fiscal"
        },
        {
          name: "",
          empty: true,
          children: [
            {
              name: "",
              empty: true,
              children: [
                {
                  name: "Diretoria Executiva",
                  children: [
                    {
                      name: "Diretor Administrativo"
                    },
                    {
                      name: "Diretor Presidente"
                    },
                    {
                      name: "Diretor Técnico"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: "Conselho Deliberativo"
        }
      ]
    };

    const MyNodeComponent = ({ node }) => {
      return (
        <div className={node.empty ? "" : classes.initechNode}>{node.name}</div>
      );
    };

    return (
      <>
        <div className={classNames(classes.intro, classes.section)}>
          <div className={classes.container}>
            <h2>Estrutura da ABMR</h2>
            <p>
              Abaixo está representado de maneira simplificada a estrutura de
              funcionamento interno da ABMR:
            </p>
            <div className={classNames(classes.graph)}>
              <div className={classes.container}>
                <OrgChart tree={initechOrg} NodeComponent={MyNodeComponent} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(OrganizationGraph);
