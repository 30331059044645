import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0 100px"
  },
  section: {
    padding: "100px 100px 50px 100px",
    backgroundColor: "white",
    margin: "0 auto"
  },
  title: {
    fontSize: "30px",
    fontWeight: "700",
    textAlign: "center"
  },
  description: {
    textAlign: "center",
    fontSize: "18px"
  },
  date: {
    textAlign: "right",
    fontSize: "12px",
    marginBottom: "12px",
    color: "#494949"
  },
  content: {
    textAlign: "justify",
    fontSize: "15px",
    color: "#494949",
    marginBottom: "15px"
  },
  img: {
    backgroundRepeat: "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: 400,
    marginBottom: 30
  },
  backButton: {
    padding: "10px 10px 10px 0",
    fontFamily: "Roboto, sans-serif",
    color: "#494949",
    fontSize: "15px",
    margin: "30px 0 10px",
    "& :active": {
      color: "#108a86"
    }
  },

  "@media screen and (min-width: 1280px)": {
    section: {
      maxWidth: "1200px"
    },
    title: {
      fontSize: "30px"
    },
    description: {
      fontSize: "20px"
    },
    img: {
      height: 400
    }
  },

  "@media screen and (max-width: 960px)": {
    container: {
      padding: "0 50px"
    },
    title: {
      fontSize: "25px"
    },
    description: {
      fontSize: "16px"
    },
    img: {
      height: 300
    }
  },

  "@media screen and (max-width: 768px)": {
    section: {
      padding: "100px 30px 30px 30px",
      backgroundColor: "white"
    },
    title: {
      fontSize: "23px"
    },
    description: {
      fontSize: "14px"
    },
    content: {
      fontSize: "12px",
      marginBottom: "12px"
    },
    date: {
      fontSize: "10px"
    },
    img: {
      display: "none"
    }
  },

  "@media screen and (max-width: 570px)": {
    container: {
      padding: "0"
    },
    section: {
      padding: "100px 30px 30px 30px",
      backgroundColor: "white"
    },
    title: {
      fontSize: "23px"
    },
    description: {
      fontSize: "14px"
    },
    content: {
      fontSize: "12px",
      marginBottom: "12px"
    },
    date: {
      fontSize: "10px"
    },
    img: {
      display: "none"
    }
  }
}));

export default useStyles;
