import React, { useEffect, useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import {
  Chip,
  Collapse,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import MyMap from "./MapMarkers";
import { getCities, populateUFs } from "../../../utils/cityAndState";
import { Alert, Autocomplete } from "@material-ui/lab";
import { powerList } from "../../../assets/projectsFormData";
import ProjectStyle from "../Styles/ProjectStyle";
import {
  getProjects,
  getPowerResources
} from "../../../common/api/projects/project";
import IconSubtitle from "../../../components/IconSubtitle";

const ProjectsMap = () => {
  const classes = ProjectStyle();

  const [allResources, setAllResources] = useState([]);
  const [resourcesShowed, setResourcesShowed] = useState([]);
  const [showedCities, setShowedCities] = useState(false);
  const [uf, setUf] = useState([]);
  const [cities, setCities] = useState([]);
  const [estado, setEstado] = useState();
  const [cidade, setCidade] = useState();
  const [recursos, setRecursos] = useState([]);
  const [potencia, setPotencia] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const power = powerList;

  function listResources(projetos) {
    const listProjects = [...projetos];
    const resourcesList = listProjects.map(project => {
      return project.resources.map((resource, index) => {
        return {
          id: `${project.id}-${index}`,
          title: project.title,
          city: project.location.city,
          uf: project.location.uf,
          lat: project.location.latitude + 0.0001 * (index + 1),
          lng: project.location.longitude + 0.0001 * (index + 1),
          name: resource.name,
          power: resource.value
        };
      });
    });
    setResourcesShowed(resourcesList.flat());
    setAllResources(resourcesList.flat());
  }

  const setProjects = async () => {
    const projects = await getProjects();
    if (projects.data.length > 0) {
      listResources(projects.data);
    }
  };

  useEffect(() => {
    async function settings() {
      const resources = await getPowerResources();
      setSuggestions(resources.data);
      const ufs = await populateUFs();
      setUf(ufs);
    }
    settings();
    setProjects();
  }, []);

  useEffect(() => {
    let filteredResources = [...allResources];

    if (cidade) {
      filteredResources = filteredResources.filter(
        resource => resource.city === cidade
      );
    }
    if (estado) {
      filteredResources = filteredResources.filter(
        resource => resource.uf === estado
      );
    }
    if (recursos.length > 0) {
      const selectedRecursos = recursos.map(recurso => recurso.name);
      filteredResources = filteredResources.filter(recurso => {
        const intersection = selectedRecursos.includes(recurso.name);
        return intersection === true;
      });
    }
    if (potencia) {
      filteredResources = filteredResources.filter(
        resource =>
          resource.power < potencia.max && resource.power >= potencia.min
      );
    }
    setResourcesShowed(filteredResources);
  }, [cidade, estado, recursos, potencia]);

  const changeEstado = e => {
    if (!e.target.value) {
      setEstado("");
      setCidade("");
      setShowedCities(false);
      return null;
    }
    setEstado(e.target.value.nome);
    async function populateCities() {
      const listCities = await getCities(e.target.value.id);
      setCities(listCities);
    }
    populateCities();
    setShowedCities(true);
    return null;
  };

  const changeCidade = e => {
    if (!e.target.value) {
      setCidade("");
      return null;
    }
    setCidade(e.target.value);
  };

  const changeRecursos = tags => {
    if (!tags) {
      setRecursos([""]);
      return null;
    }
    setRecursos(tags);
  };

  const changePotencia = e => {
    if (!e.target.value) {
      setPotencia("");
      return null;
    }
    setPotencia(e.target.value);
  };

  return (
    <>
      <Collapse in={resourcesShowed.length <= 0} className={classes.alert}>
        <Alert severity="error">Não há Projetos cadastrados</Alert>
      </Collapse>
      <LoadScript googleMapsApiKey={"AIzaSyBfKbEiJgB9UNKsBWwzlTE-CUHerNc6bf0"}>
        <Container className={classes.projectsMap}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.menu}
            container
          >
            <Typography variant="h6" className={classes.title}>
              FILTROS
            </Typography>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Estado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  onChange={changeEstado}
                  label="Estado"
                  inputProps={{
                    name: "uf",
                    id: "uf"
                  }}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {uf.map(location => (
                    <MenuItem key={location.id} value={location}>
                      {location.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Municipio
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  onChange={changeCidade}
                  label="Municipio"
                  inputProps={{
                    name: "city",
                    id: "city"
                  }}
                  disabled={!showedCities}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {cities.map((city, i) => (
                    <MenuItem key={i} value={city.nome}>
                      {city.nome}
                    </MenuItem>
                  ))}
                  {!cities.length && (
                    <MenuItem value="">Sem projetos nesse estado</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-filled"
                getOptionLabel={option => option.name}
                options={suggestions?.map(option => option)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(event, tagsValues) => changeRecursos(tagsValues)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Recursos Energéticos"
                    name="tags"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Potência Instalada
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  onChange={changePotencia}
                  label="Potência Instalada"
                  inputProps={{
                    name: "power",
                    id: "power"
                  }}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {power.map((powertype, i) => (
                    <MenuItem key={`${i}-${powertype}`} value={powertype}>
                      {powertype.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <MyMap resourcesShowed={resourcesShowed} />
          </Grid>
          <div className={classes.subtitleContainer}>
            <Typography variant="h6" className={classes.title}>
              Recursos Energéticos
            </Typography>
            <IconSubtitle color="#108a86" title="Fotovoltáico" />
            <IconSubtitle color="#6b05be" title="Biogás" />
            <IconSubtitle color="#ffbb00" title="Eólica" />
            <IconSubtitle color="#0c2bb6" title="Célula de Combustível" />
            <IconSubtitle color="#407406" title="Geração a Diesel" />
            <IconSubtitle color="#29b931" title="Bateria de Lítio" />
            <IconSubtitle color="#738cff" title="Bateria de Chumbo" />
            <IconSubtitle color="#13c8ff" title="Bateria de Sódio" />
            <IconSubtitle color="#ff4f19" title="Outras Baterias" />
            <IconSubtitle color="#da2c57" title="Hidrogênio" />
            <IconSubtitle color="#ad1d1d" title="Marémotriz" />
            <IconSubtitle color="#c57000" title="Veículo Elétrico V2G" />
            <IconSubtitle color="#295bb9" title="Outros" />
          </div>
        </Container>
      </LoadScript>
    </>
  );
};

export default ProjectsMap;
