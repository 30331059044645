import React from "react";
import classNames from "classnames";

import { List, ListItem, ListItemIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";

import styles from "../Styles/benefitsStyle";
import pdf from "assets/Teste.pdf";

const useStyles = makeStyles(styles);

export default function BenefitsSection() {
  const classes = useStyles();

  return (
    <>
      <div className={classNames(classes.container, classes.section)}>
        <div>
          <h2>Benefícios ao se associar:</h2>

          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemHeading}>
                <>
                  <DoneOutlineIcon className={classes.icon} />
                  <span className={classes.listItemTitle}>
                    Oportunidade de Negócios
                  </span>
                </>
              </ListItemIcon>
              – A ABMR realiza a interface entre o associado e os demais
              associados possibilitando parcerias para criação de novos negócios
              entre eles;
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemHeading}>
                <>
                  <DoneOutlineIcon className={classes.icon} />
                  <span className={classes.listItemTitle}>
                    Oportunidade de Projetos
                  </span>
                </>
              </ListItemIcon>
              – Utilizando sua rede de associados a instituição realiza
              aproximações e direciona demandas de projetos aos associados que
              tenham possibilidade de desenvolver;
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemHeading}>
                <>
                  <DoneOutlineIcon className={classes.icon} />
                  <span className={classes.listItemTitle}>
                    Oportunidade de Parcerias
                  </span>
                </>
              </ListItemIcon>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemHeading}>
                <>
                  <DoneOutlineIcon className={classes.icon} />
                  <span className={classes.listItemTitle}>
                    Mantenha-se atualizado
                  </span>
                </>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemHeading}>
                <>
                  <DoneOutlineIcon className={classes.icon} />
                  <span className={classes.listItemTitle}>
                    Participação em eventos relativos ao setor
                  </span>
                </>
              </ListItemIcon>
              – A ABMR promove a divulgação e organização em eventos.
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemHeading}>
                <>
                  <DoneOutlineIcon className={classes.icon} />
                  <span className={classes.listItemTitle}>
                    Participação de assembleias
                  </span>
                </>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemHeading}>
                <>
                  <DoneOutlineIcon className={classes.icon} />
                  <span className={classes.listItemTitle}>
                    Engajamento no mercado (Selo ABMR)
                  </span>
                </>
              </ListItemIcon>
            </ListItem>
          </List>
        </div>

        <a href={pdf} target="_blank" rel="noopener noreferrer">
          Saiba mais sobre os benefícios
        </a>
      </div>
    </>
  );
}
