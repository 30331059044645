import React, { useEffect, useState, forwardRef, useCallback } from "react";
import classNames from "classnames";

import api from "../../../common/api/config/api";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  FormGroup,
  Checkbox,
  Link
} from "@material-ui/core";

import {
  estados,
  categoriasDeAssociacaoPF,
  categoriasDeAssociacaoPJ,
  meiosDeCobranca,
  tempoDeAtuacao,
  itensConhecimento
} from "../../../assets/associateFormData";

import FormModal from "./FormModal.js";
import TUmodal from "./TUmodal.js";

import styles from "../Styles/formStyle";

const useStyles = makeStyles(styles);

function FormSection(props, ref) {
  const classes = useStyles();

  const [values, setValues] = useState({ entidade: "pj" });
  const [open, setOpen] = useState(false);
  const [openTU, setOpenTU] = useState(false);
  const [items, setItems] = useState({
    inadimplencia: false,
    boletos: false,
    estatuto: false,
    atuacao: false
  });
  const [result, setResult] = useState(null);
  const [workGroups, setWorkGroups] = useState([]);
  const [companyAreas, setCompanyAreas] = useState([]);
  const [emailCheck, setEmailCheck] = useState();
  const [errorText, setErrorText] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setResult(null);
  };

  const handleOpenTU = () => {
    setOpenTU(true);
  };

  const handleCloseTU = () => {
    setOpenTU(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleClickOpen();
    postAssociate();
  };

  const postAssociate = async () => {
    const headers = {
      "Content-Type": "application/json"
    };

    const newTempoDeAtuacao = tempoDeAtuacao.find(
      tempo => tempo.pt_br === values.tempodeatuacao
    );

    const newMeioDeCobranca = meiosDeCobranca.find(
      cobranca => cobranca.pt_br === values.meiodecobranca
    );

    var body = {
      name: values.nomecompleto,
      billingEmail: values.emailcobranca.trim(),
      contactEmail: values.emailcontato.trim(),
      contact: {
        principalPhone: values.telefone,
        zipCode: values.cep,
        address: values.endereco,
        city: values.cidade,
        uf: values.estado
      },
      billingMethod: newMeioDeCobranca.us,
      companyCategory: values.areadeatuacao,
      workgroup: values.grupodetrabalho,
      url: values.site,
      worktime: newTempoDeAtuacao.us,
      distributedGeneration: true,
      acceptedTerms: {
        protest: items.inadimplencia,
        disfellowshipping: items.boletos,
        knowStatue: items.estatuto,
        electricWork: items.atuacao,
        cookies: items.lgpd
      }
    };
    if (result !== "error") {
      if (values.entidade && values.entidade === "pf") {
        body = {
          ...body,
          cpf: values.cpf
        };
        await api
          .post(`/people`, body, { headers })
          .then(() => {
            setResult("success");
          })
          .catch(() => {
            setResult("error");
          });
      } else if (values.entidade === "pj") {
        body = {
          ...body,
          businessName: values.nomefantasia,
          cnpj: values.cnpj,
          legalResponse: values.responsavellegal,
          referenceContact: values.pessoacontato
        };
        await api
          .post(`/companies`, body, { headers })
          .then(() => {
            setResult("success");
          })
          .catch(() => {
            setResult("error");
          });
      }
    }
  };

  const authEmail = async event => {
    const email = event.target.value;
    try {
      const response = await api.get(`/users?email=${email}`);
      if (response.data.exist === true) {
        setEmailCheck(true);
        setErrorText("E-mail já cadastrado");
        setResult("error");
      } else {
        setEmailCheck(false);
        setErrorText(null);
        setResult(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = event => {
    const auxValues = { ...values };
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  const handleCheck = event => {
    setItems({ ...items, [event.target.name]: event.target.checked });
  };

  const getAllCompaniesCategoryAndWorkGroups = useCallback(() => {
    const categoryCompanies = api.get(`/companies/categories`);
    const workgroups = api.get(`/workgroups`);
    Promise.all([categoryCompanies, workgroups]).then(results => {
      results.map(result => {
        result.config.url === "/companies/categories"
          ? setCompanyAreas(result.data)
          : setWorkGroups(result.data);
        return result;
      });
    });
  }, []);

  useEffect(() => getAllCompaniesCategoryAndWorkGroups(), [
    getAllCompaniesCategoryAndWorkGroups
  ]);

  const { inadimplencia, boletos, estatuto, atuacao, lgpd } = items;
  const error =
    [inadimplencia, boletos, estatuto, atuacao, lgpd].filter(v => v).length !==
    5;

  return (
    <div ref={ref} className={classNames(classes.background, classes.section)}>
      <h5>Tem interesse?</h5>
      <h2>Associe-se</h2>
      <Container className={classes.formWrapper} component="main" maxWidth="sm">
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <FormControl component="fieldset" style={{ marginBottom: 20 }}>
              <FormLabel component="legend">Entidade da empresa:</FormLabel>
              <Grid item xs={12}>
                <RadioGroup
                  aria-label="entidade"
                  name="entidade"
                  required
                  value={values["entidade"]}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="pj"
                    control={<Radio />}
                    label="Pessoa Jurídica"
                  />
                  <FormControlLabel
                    value="pf"
                    control={<Radio />}
                    label="Pessoa Física"
                  />
                </RadioGroup>
              </Grid>
            </FormControl>
            <Grid item xs={12}>
              <TextField
                name="nomecompleto"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="nomecompleto"
                label="Nome Completo"
              />
            </Grid>
            {values["entidade"] === "pj" ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="nomefantasia"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="nomefantasia"
                    label="Nome Fantasia"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="razaosocial"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="razaosocial"
                    label="Razão Social"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="cnpj"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="cnpj"
                    label="CNPJ"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="responsavellegal"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="responsavellegal"
                    label="Responsável legal da empresa"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="pessoacontato"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="pessoacontato"
                    label="Pessoa de referência para contato"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    required
                    style={{ width: "100%", backgroundColor: "white" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Categoria de associação
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue=""
                      value={values["categoriadeassociacaopj"] || ""}
                      onChange={handleChange}
                      label="Categoria de associação"
                      inputProps={{
                        name: "categoriadeassociacaopj",
                        id: "categoriadeassociacaopj"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {categoriasDeAssociacaoPJ.map((categoria, i) => (
                        <MenuItem key={i} value={categoria}>
                          {categoria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="cpf"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="cpf"
                    label="CPF"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    required
                    style={{ width: "100%", backgroundColor: "white" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Categoria de associação
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue=""
                      value={values["categoriadeassociacaopf"] || ""}
                      onChange={handleChange}
                      label="Categoria de associação"
                      inputProps={{
                        name: "categoriadeassociacaopf",
                        id: "categoriadeassociacaopf"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {categoriasDeAssociacaoPF.map((categoria, i) => (
                        <MenuItem key={i} value={categoria}>
                          {categoria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                name="emailcobranca"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="emailcobranca"
                label="E-mail para cobrança"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="emailcontato"
                variant="outlined"
                onChange={handleChange}
                onBlur={authEmail}
                error={emailCheck}
                helperText={errorText}
                required
                fullWidth
                id="emailcontato"
                label="E-mail de contato entre a ABMR"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                name="telefone"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="phone"
                label="Telefone"
                autoComplete="tel"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                name="cep"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="cep"
                label="CEP"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="endereco"
                variant="outlined"
                value={values["address"]}
                onChange={handleChange}
                required
                fullWidth
                id="address"
                label="Endereço"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                name="cidade"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="cidade"
                label="Cidade"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl
                variant="outlined"
                required
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Estado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  value={values["estado"] || ""}
                  onChange={handleChange}
                  label="Estado"
                  inputProps={{
                    name: "estado",
                    id: "estado"
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  {estados.map((estado, i) => (
                    <MenuItem key={i} value={estado}>
                      {estado}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                required
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Meio de cobrança
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  value={values["meiodecobranca"] || ""}
                  onChange={handleChange}
                  label="Meio de cobrança"
                  inputProps={{
                    name: "meiodecobranca",
                    id: "meiodecobranca"
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  {meiosDeCobranca.map((cobranca, i) => (
                    <MenuItem key={i} value={cobranca.pt_br}>
                      {cobranca.pt_br}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                required
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Área de atuação principal
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  value={values["areadeatuacao"] || ""}
                  onChange={handleChange}
                  label="Área de atuação principal"
                  inputProps={{
                    name: "areadeatuacao",
                    id: "areadeatuacao"
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  {companyAreas.map((area, i) => (
                    <MenuItem key={i} value={area}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                required
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Grupo de trabalho prioritário
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  value={values["grupodetrabalho"] || ""}
                  onChange={handleChange}
                  label="Grupo de trabalho prioritário"
                  inputProps={{
                    name: "grupodetrabalho",
                    id: "grupodetrabalho"
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  {workGroups.map((group, i) => (
                    <MenuItem key={i} value={group}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="site"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="site"
                label="Site/LinkedIn da empresa"
                helperText="http://www.sitedaempresa.com"
                placeholder="http://"
                type="url"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                required
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tempo de atuação no mercado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue=""
                  value={values["tempodeatuacao"] || ""}
                  onChange={handleChange}
                  label="Tempo de atuação no mercado"
                  inputProps={{
                    name: "tempodeatuacao",
                    id: "tempodeatuacao"
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  {tempoDeAtuacao.map((tempo, i) => (
                    <MenuItem key={i} value={tempo.pt_br}>
                      {tempo.pt_br}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <FormControl
              required
              error={error}
              component="fieldset"
              style={{ margin: "20px 0" }}
            >
              <FormGroup>
                <h4>
                  Ao informar meus dados, eu concordo com a
                  <Link onClick={handleOpenTU}> Política de Privacidade.</Link>
                </h4>
                {itensConhecimento.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={items[item.name]}
                        onChange={handleCheck}
                        name={item.name}
                      />
                    }
                    label={item.text}
                  />
                ))}
              </FormGroup>
              <FormHelperText>
                Itens para conhecimento do funcionamento da ABMR. Obrigatório
                marcar todos.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={error}
          >
            Cadastrar
          </Button>
          <FormModal open={open} result={result} handleClose={handleClose} />
          <TUmodal open={openTU} handleClose={handleCloseTU} />
        </form>
      </Container>
    </div>
  );
}

export default forwardRef(FormSection);
