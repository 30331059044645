import React, { useState } from "react";
import {
  useStyle,
  StyledTableCell,
  StyledTableRow,
  StyledTableHeader,
  StyledTableSubCell
} from "../Styles/projectListStyle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";

// import { get } from "common/api/config/api_methods";
// import { getToken } from "common/security/token";

const ProjectList = () => {
  const classes = useStyle();
  const [projectActive, setProjectActive] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const [page, setPage] = useState(0);

  // useEffect(() => {
  //   async function getProjects() {
  //     await get("projects/", getToken()).then(response => {
  //       setProjectsList(response.data);
  //     });
  //   }
  //   getProjects();
  // }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className={classes.container}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            {/* <caption>
              {i + 1}. {project.title}
            </caption> */}
            <TableHead>
              <StyledTableHeader>
                <div>Projeto</div>
                <div align="left">Contrato</div>
                <div align="left">Cliente</div>
                <div align="left">Executores</div>
                <div align="left">Data</div>
                <div align="left">Localização</div>
                <div align="left" style={{ flexBasis: "19%" }}>
                  Recursos Energéticos
                </div>
              </StyledTableHeader>
            </TableHead>
            <TableBody>
              {projectsList ? (
                projectsList.map((project, i) => (
                  <StyledTableRow
                    key={`${project.title}-${i}`}
                    className={
                      projectActive === project.title
                        ? classes.borderActive
                        : null
                    }
                  >
                    <Accordion
                      onChange={() => setProjectActive(project.title)}
                      style={{
                        borderRadius: "0px",
                        borderBottom: "1px solid #bbb"
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        className={classes.summary}
                      >
                        <StyledTableCell align="left">
                          {project.fullName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {project.contract}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {project.client}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {project.executors}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {project.start} - {project.finish}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {`${
                            project.location.city
                              ? project.location.city + "/"
                              : " "
                          } ${project.location.uf}`}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{ flexBasis: "16%" }}
                        >
                          {project.resources.join(", ")}
                        </StyledTableCell>
                      </AccordionSummary>
                      <AccordionDetails className={classes.details}>
                        <StyledTableSubCell
                          align="left"
                          style={{ flexBasis: "42%", paddingRight: "10px"}}
                        >
                          <strong>Objetivo</strong> <br /> {project.objective}
                        </StyledTableSubCell>
                        <StyledTableSubCell align="left">
                          <strong>Garantia Física</strong> <br />{" "}
                          {project.garantie}
                        </StyledTableSubCell>
                        <StyledTableSubCell align="left">
                          <strong>Potência Declarada de Ilhamento</strong>{" "}
                          <br /> {project.islandPower}
                        </StyledTableSubCell>
                        <StyledTableSubCell align="left">
                          <strong>Ponto de Conexão</strong> <br />{" "}
                          {project.conectionPoints}
                        </StyledTableSubCell>
                        <StyledTableSubCell align="left">
                          <strong>Potência Instalada</strong> <br />{" "}
                          {project.power}
                        </StyledTableSubCell>
                      </AccordionDetails>
                    </Accordion>
                  </StyledTableRow>
                ))
              ) : (
                <div>
                  <h3 style={{ color: "#999", fontWeight: 500 }}>
                    Não existem projetos cadastrados no momento
                  </h3>
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.center}>
          <Pagination
            count={1}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
          />
        </div>
      </div>
    </>
  );
};

export default ProjectList;
