import React, { useRef, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import SignIn from "components/SignIn/SignIn.js";
import SignUp from "components/SignUp/SignUp.js";
import ForgotPassword from "components/ForgotPassword/ForgotPassword.js";

function getStepContent(step) {
  switch (step) {
    case 0:
      return <ForgotPassword />;
    case 1:
      return <SignIn />;
    case 2:
      return <SignUp />;
    default:
      throw new Error("Request not found");
  }
}

export default function HeaderModalForms(props) {
  const [activeStep, setActiveStep] = React.useState(1);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function useOutsideAlerter(ref) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        props.setClassicModal(false);
        setTimeout(setActiveStep(1), 5000);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }

  return (
    <div ref={wrapperRef}>
      <React.Fragment>
        <CssBaseline />
        <React.Fragment>
          {getStepContent(activeStep)}

          {activeStep === 0 && (
            <Grid
              container
              justify="flex-end"
              style={{ marginBottom: 15, paddingRight: 32, paddingLeft: 32 }}
            >
              <Grid item>
                <Link to="#" onClick={handleNext} variant="body2">
                  Faça Login
                </Link>
              </Grid>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid
              container
              style={{ marginBottom: 15, paddingRight: 32, paddingLeft: 32 }}
            >
              <Grid item xs>
                <Link to="#" onClick={handleBack} variant="body2">
                  Esqueceu sua senha?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/associe-se" variant="body2">
                  {"Não tem uma conta? Cadastre-se"}
                </Link>
              </Grid>
            </Grid>
          )}

          {activeStep === 2 && (
            <Grid
              container
              justify="flex-end"
              style={{ marginBottom: 15, paddingRight: 32, paddingLeft: 32 }}
            >
              <Grid item>
                <Link onClick={handleBack} variant="body2">
                  Já possui uma conta? Faça login
                </Link>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      </React.Fragment>
    </div>
  );
}
