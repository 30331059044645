import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Link
} from "@material-ui/core";

// @material-ui/icons
import Event from "@material-ui/icons/Event";
import Announcement from "@material-ui/icons/Announcement";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";

import styles from "../Styles/newsAndEventsStyle.js";

const useStyles = makeStyles(styles);

export default function NewsAndEvents() {
  const classes = useStyles();

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="nav-tabs">
          <Grid container>
            <Grid item xs={12} sm={12} md={6} className={classes.description}>
              <h2>Acompanhe as últimas novidades</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <CustomTabs
                headerColor="primary"
                style={{ marginRight: "auto" }}
                tabs={[
                  {
                    tabName: "Notícias",
                    tabIcon: Announcement,
                    tabContent: (
                      <>
                        <List className={classes.list}>
                          <ListItemLink href="#">
                            <ListItemAvatar>
                              <Avatar>
                                <ImageIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="CRA analisa projeto que libera uso de energia renovável em assentamentos rurais"
                              secondary="02/03/2020"
                            />
                          </ListItemLink>

                          <Divider />

                          <ListItemLink href="#">
                            <ListItemAvatar>
                              <Avatar>
                                <WorkIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Energia solar cresce forte no Brasil em 2019"
                              secondary="02/03/2020"
                            />
                          </ListItemLink>

                          <Divider />

                          <ListItemLink href="#">
                            <ListItemAvatar>
                              <Avatar>
                                <BeachAccessIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Energia solar: limpa e atrativa"
                              secondary="02/03/2020"
                            />
                          </ListItemLink>
                        </List>
                        <Link to="#">Ver mais </Link>
                      </>
                    )
                  },
                  {
                    tabName: "Eventos",
                    tabIcon: Event,
                    tabContent: (
                      <List className={classes.list}>
                        <ListItemLink href="#">
                          <ListItemAvatar>
                            <Avatar>
                              <ImageIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Congresso"
                            secondary="02/03/2020"
                          />
                        </ListItemLink>

                        <Divider />

                        <ListItemLink href="#">
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Palestra"
                            secondary="02/03/2020"
                          />
                        </ListItemLink>

                        <Divider />

                        <ListItemLink href="#">
                          <ListItemAvatar>
                            <Avatar>
                              <BeachAccessIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Workshop"
                            secondary="02/03/2020"
                          />
                        </ListItemLink>
                        <Link to="#">Ver mais </Link>
                      </List>
                    )
                  }
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
