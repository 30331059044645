import React from "react";
// react plugin for creating date-time-picker
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import CustomButton from "components/CustomButtons/CustomButton.js";
import HeaderModalForms from "components/HeaderModal/HeaderModalForms.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);

export default function SignUpModal(props) {
  const classes = useStyles();
  const { buttonTitle, buttonClasses } = props;

  const [classicModal, setClassicModal] = React.useState(false);
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <CustomButton
          className={buttonClasses}
          onClick={() => setClassicModal(true)}
          color="primary"
          round
        >
          {buttonTitle}
        </CustomButton>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={classicModal}
          keepMounted
          onClose={() => setClassicModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => setClassicModal(false)}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <HeaderModalForms setClassicModal={setClassicModal} />
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}
