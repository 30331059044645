import { post, patch } from "../config/api_methods";
import { getToken } from "../../security/token";

const PROJECT_PATH = "/auth";
const PERSON_USER_PATH = "/users/person";

export const authenticateUser = params => {
  const response = post(PROJECT_PATH, params);
  return response;
};

export const activePerson = params => {
  const response = patch(PERSON_USER_PATH, params, getToken());
  return response;
};

export default authenticateUser;
