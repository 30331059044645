import { container } from "assets/jss/material-kit-react.js";

const aboutStyle = theme => ({
  container,
  section: {
    padding: "60px 20px",

    "& h2": {
      color: "#081624",
      marginBottom: 30,
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif"
    },

    "& p": {
      color: "#666",
      fontSize: 16,
      lineHeight: 2
    }
  }
});

export default aboutStyle;
