import React from "react";

import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container
} from "@material-ui/core";
import Logo from "assets/img/logo-apple.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#198a86"
  }
}));

export default function ForgotPassword(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.wrapper}>
        <img src={Logo} alt="Logo" height={50} style={{ marginBottom: 10 }} />
        <Typography component="h1" variant="h5">
          Esqueceu sua senha?
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Recuperar senha
          </Button>
        </form>
      </div>
    </Container>
  );
}
