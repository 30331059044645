import { fade } from "@material-ui/core/styles";
import { container } from "assets/jss/material-kit-react.js";

const articlesListStyle = theme => ({
  container,
  section: {
    padding: "60px 15px 90px",

    "& p": {
      color: "#718090",
      fontSize: 16
    }
  },
  title: {
    fontWeight: "bold",
    color: "#666",
    fontSize: 13,
    marginTop: 0
  },
  searchBar: {
    backgroundColor: "#eee",
    borderRadius: "6px",
    padding: "10px 0",
    marginBottom: 40
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.85),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.65)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  inputRoot: {
    color: "#333"
  }
});

export default articlesListStyle;
