import React, { useState } from "react";
import classNames from "classnames";
import Slider from "react-slick";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import Parallax from "components/Parallax/Parallax.js";

import styles from "../Styles/bannerStyle";

const useStyles = makeStyles(styles);

export default function BannerSection() {
  const classes = useStyles();

  const [activeSlide, setActiveSlide] = useState(0);

  const nextClick = e => {
    activeSlide === 2 ? setActiveSlide(0) : setActiveSlide(activeSlide + 1);
  };

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    afterChange: nextClick
  };

  return (
    <div>
      <h2> Single Item</h2>
      <Slider {...settings} className={classes.overlayWrapper}>
        <Parallax image={require("assets/img/hero-slider/banner001-a.jpg")}>
          <div className={classes.overlay}></div>
          <div className={classNames(classes.container, classes.section)}>
            <Grid container>
              <Grid item>
                <div className={classes.wrapper}>
                  {/* <Slide
                    direction="up"
                    in={activeSlide === 0}
                    mountOnEnter
                    timeout={(500, 500, 1000)}
                  > */}
                  <h1 className={classes.title}>
                    <span>Bem-vindo a </span>
                    <span>Microrredes</span>
                  </h1>
                  {/* </Slide> */}
                </div>
                <div className={classes.wrapper}>
                  {/* <Slide
                    direction="up"
                    in={activeSlide === 0}
                    mountOnEnter
                    timeout={(2000, 2000, 1000)}
                  > */}
                  <Button
                    href="/sobre-nos"
                    className={classNames(classes.seeMore, classes.button)}
                  >
                    Saiba mais
                  </Button>
                  {/* </Slide>
                  <Slide
                    direction="up"
                    in={activeSlide === 0}
                    mountOnEnter
                    timeout={(4000, 4000, 1000)}
                  > */}
                  <Button
                    href="/projetos"
                    className={classNames(classes.studies, classes.button)}
                  >
                    Nossos projetos
                  </Button>
                  {/* </Slide> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Parallax>
        <Parallax image={require("assets/img/hero-slider/banner002-a.jpg")}>
          <div className={classes.overlay}></div>
          <div className={classNames(classes.container, classes.section)}>
            <Grid container>
              <Grid item>
                <div className={classes.wrapper}>
                  {/* <Slide
                    direction="up"
                    in={activeSlide === 1}
                    mountOnEnter
                    timeout={(500, 500, 1000)}
                  > */}
                  <h1 className={classes.title}>
                    <span>Bem-vindo a </span>
                    <span>Microrredes</span>
                  </h1>
                  {/* </Slide> */}
                </div>
                <div className={classes.wrapper}>
                  {/* <Slide
                    direction="up"
                    in={activeSlide === 1}
                    mountOnEnter
                    timeout={(2000, 2000, 1000)}
                  > */}
                  <Button
                    href="/sobre-nos"
                    className={classNames(classes.seeMore, classes.button)}
                  >
                    Saiba mais
                  </Button>
                  {/* </Slide>
                  <Slide
                    direction="up"
                    in={activeSlide === 1}
                    mountOnEnter
                    timeout={(4000, 4000, 1000)}
                  > */}
                  <Button
                    href="/projetos"
                    className={classNames(classes.studies, classes.button)}
                  >
                    Nossos projetos
                  </Button>
                  {/* </Slide> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Parallax>
        <Parallax image={require("assets/img/hero-slider/banner003-a.jpg")}>
          <div className={classes.overlay}></div>
          <div className={classNames(classes.container, classes.section)}>
            <Grid container>
              <Grid item>
                <div className={classes.wrapper}>
                  {/* <Slide
                    direction="up"
                    in={activeSlide === 2}
                    mountOnEnter
                    timeout={(500, 500, 1000)}
                  > */}
                  <h1 className={classes.title}>
                    <span>Bem-vindo a </span>
                    <span>Microrredes</span>
                  </h1>
                  {/* </Slide> */}
                </div>
                <div className={classes.wrapper}>
                  {/* <Slide
                    direction="up"
                    in={activeSlide === 2}
                    mountOnEnter
                    timeout={(2000, 2000, 1000)}
                  > */}
                  <Button
                    href="/sobre-nos"
                    className={classNames(classes.seeMore, classes.button)}
                  >
                    Saiba mais
                  </Button>
                  {/* </Slide>
                  <Slide
                    direction="up"
                    in={activeSlide === 2}
                    mountOnEnter
                    timeout={(4000, 4000, 1000)}
                  > */}
                  <Button
                    href="/projetos"
                    className={classNames(classes.studies, classes.button)}
                  >
                    Nossos projetos
                  </Button>
                  {/* </Slide> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Parallax>
      </Slider>
    </div>
  );
}
