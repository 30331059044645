import React, { useState } from "react";

import {
  Grid,
  Toolbar,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { partnersList } from "../../../assets/partnersList";
import PhoneIcon from "@material-ui/icons/Phone";
import SiteIcon from "@material-ui/icons/Language";
import EmailIcon from "@material-ui/icons/Email";
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../Styles/partnerListStyle";

const useStyles = makeStyles(styles);

// A partir de um array de objetos,
// retorna um novo array apenas com uma chave do objeto,
// pulando as repetições de valores dessa chave
// eslint-disable-next-line no-unused-vars
function removeDuplicateKeys(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i][prop]);
  }
  return newArray;
}

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function PartnersList() {
  const classes = useStyles();
  // const [partners, setPartners] = useState([]);
  const partners = [...partnersList];
  const [displayedPartners, setDisplayedPartners] = useState([...partnersList]);
  const [businessArea, setBusinessArea] = useState("");
  const [businessName, setBusinessName] = useState("");
  const areasOfBusiness = [
    "Comercializadora de Energia Elétrica (ACL)",
    "Confederação ou Federação do Setor Elétrico",
    "Distribuidor de Equipamentos",
    "Distribuidor de Energia Elétrica (ACR)",
    "Empresa de Advocacia",
    "Empresa de Armazenamento de Energia",
    "Empresa de Auditoria",
    "Empresa de Consultoria",
    "Empresa de Engenharia",
    "Empresa de Serviços de Operação e Manutenção",
    "Empresa de Treinamento e Capacitação",
    "Empresa Integradora",
    "Empresa Projetista",
    "Empresa Seguradora",
    "Empresa de Arquitetura",
    "Fabricante de Materiais Elétricos e Diversos",
    "Geradora de Energia ",
    "Instituição de Ensino Superior",
    "Instituto ou Centro de Pesquisa",
    "Instituição Financeira",
    "Profissional do setor"
  ];

  const onNameChange = (event, value) => {
    setBusinessArea("");

    if (value !== null) {
      setBusinessName(value.name);
      setDisplayedPartners([value]);
    } else {
      setDisplayedPartners([...partners]);
    }
  };

  const onAreaChange = event => {
    setBusinessArea(event.target.value);

    if (event.target.value !== "") {
      var aux = partners.filter(obj => {
        if (obj.area === event.target.value) {
          return true;
        } else {
          return false;
        }
      });
      setDisplayedPartners(aux);
    } else {
      setDisplayedPartners([...partners]);
    }
  };

  //  PARA QUANDO AS TAGS DE RECURSOS ESTIVEREM VINDO DA API
  // useEffect(() => {
  //   async function setPartners() {
  //     const allPartners = await getPartners();
  //     setPartners(allPartners.data);
  //     setDisplayedPartners(allPartners.data);
  //   }
  //   setPartner();
  // }, []);

  return (
    <div className={classes.section} style={{ minHeight: 693 }}>
      <Toolbar className={classes.searchBar}>
        <Grid container>
          <Grid item xs={12} style={{ marginLeft: 24 }}>
            <h6 className={classes.title}>Filtrar por</h6>
          </Grid>
          <Grid item xs={12} md={7} className={classes.search}>
            <Autocomplete
              id="busca-associado"
              options={partners}
              autoComplete={true}
              getOptionLabel={option => option.name}
              onChange={onNameChange}
              style={{ minWidth: 300, width: "100%" }}
              noOptionsText="Não encontrado"
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{
                    root: classes.inputRoot
                  }}
                  label="Nome"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.search}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Área de atuação
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={businessArea}
                onChange={onAreaChange}
                label="Área de atuação"
                style={{ minWidth: 295, width: "100%" }}
                MenuProps={MenuProps}
              >
                <MenuItem value="">
                  <em>Todas</em>
                </MenuItem>
                {areasOfBusiness.map((area, i) => (
                  <MenuItem key={i} value={area}>
                    {area}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Toolbar>

      <Grid container spacing={4} className={classes.list}>
        {displayedPartners.length > 0 ? (
          displayedPartners.map((partner, i) => (
            <Grid item xs={6} md={4} key={i} className={classes.listItem}>
              <Card>
                <img
                  className={classes.imgCardTop}
                  src={partner.img}
                  alt={partner.name}
                />
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <h4 className={classes.cardTitle}>{partner.name}</h4>
                  <div className={classes.contactInfo}>
                    {partner.contact.phone ? (
                      <h6>
                        <PhoneIcon fontSize="small" color="action" />{" "}
                        {partner.contact.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                    {partner.contact.email ? (
                      <h6>
                        <EmailIcon fontSize="small" color="action" />
                        {partner.contact.email}
                      </h6>
                    ) : (
                      ""
                    )}
                    {partner.contact.site ? (
                      <h6>
                        <SiteIcon fontSize="small" color="action" />
                        <a
                          href={partner.contact.site}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visitar site
                        </a>
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </Grid>
          ))
        ) : (
          <div className={classes.notFound}>
            <h2>Nenhum associado encontrado.</h2>
          </div>
        )}
      </Grid>
    </div>
  );
}

export default PartnersList;
