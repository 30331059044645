import React, { Component } from "react";
import classNames from "classnames";

import BannerSection from "../../../components/InnerPageBanner/InnerPageBanner.js";
import CustomEvent from "./CustomEvent";

import { withStyles } from "@material-ui/core/styles";
import styles from "../Styles/calendarStyle.js";

import { Calendar, momentLocalizer } from "react-big-calendar";
import googleAPI from "../../../utils/google-calendar/googleAPI";
import moment from "moment";

import imgPath from "assets/img/event.jpg";

import "react-big-calendar/lib/css/react-big-calendar.css";

require("moment/locale/pt-br");

const localizer = momentLocalizer(moment);

class CalendarSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }

  componentDidMount = () => {
    if (this.props.config) {
      this.getGoogleCalendarEvents();
    } else {
      console.log(
        "React Google Calendar requires you pass a configuration object"
      );
    }
  };

  getGoogleCalendarEvents = () => {
    googleAPI
      .getAllCalendars(this.props.config)
      .then(events => {
        this.setState({ events });
      })
      .catch(err => {
        throw new Error(err);
      });
  };

  render() {
    const { events } = this.state;
    const { classes } = this.props;

    return (
      <div className={classNames(classes.container, classes.section)}>
        <div className="App">
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            culture={"pt-br"}
            events={events}
            style={{ height: "100vh" }}
            messages={{
              month: "Mês",
              day: "Dia",
              week: "Semana",
              today: "Hoje",
              previous: "🡠",
              next: "🡢"
            }}
            components={{ event: CustomEvent }}
          />
        </div>

        <a href="https://eventos.microrredes.org.br" target="blank">
          <BannerSection
            title="Eventos da ABMR"
            subtitle="Confira >"
            imgPath={imgPath}
            size="cover"
            position="center"
          />
        </a>
      </div>
    );
  }
}

export default withStyles(styles)(CalendarSection);
