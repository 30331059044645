import React from "react";
import Slider from "react-slick";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../Styles/partnersStyle.js";

const useStyles = makeStyles(styles);

export default function PartnersSection() {
  const classes = useStyles();
  const settings = {
    infinite: true,
    centerPadding: "50px",
    slidesToShow: 4,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const partners = [
    {
      img: require("../../../assets/img/partners/fohat.png"),
      alt: "Fohat"
    },
    {
      img: require("../../../assets/img/partners/hioki.png"),
      alt: "Hioki"
    },
    {
      img: require("../../../assets/img/partners/itai.png"),
      alt: "ITAI"
    },
    {
      img: require("../../../assets/img/partners/pti.png"),
      alt: "PTI"
    },
    {
      img: require("../../../assets/img/partners/meihaper.png"),
      alt: "MEIHAPER"
    },
    {
      img: require("../../../assets/img/partners/ufpr.png"),
      alt: "UFPR"
    },
    {
      img: require("../../../assets/img/partners/opal.png"),
      alt: "OPAL Real-Time simulation"
    },
    {
      img: require("../../../assets/img/partners/ghm.png"),
      alt: "GHM"
    },
    {
      img: require("../../../assets/img/partners/seja-parceiro.png"),
      alt: "Seja um parceiro"
    },
    {
      img: require("../../../assets/img/partners/seja-parceiro.png"),
      alt: "Seja um parceiro"
    }
  ];

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2>Empresas Parceiras</h2>
        <Slider {...settings} className={classes.partnersSlider}>
          {partners.map(partner => {
            return (
              <div key={partner} className={classes.sliderWrapper}>
                <img src={partner.img} alt={partner.alt} />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
