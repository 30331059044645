import React from "react";

import { List, ListItem, ListItemIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BulletIcon from "@material-ui/icons/FiberManualRecord";

import styles from "../Styles/objectivesStyle";

const useStyles = makeStyles(styles);

export default function ObjectivesSection() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2>Principais Objetivos</h2>

        <List>
          <div className={classes.redLine}></div>
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Promover e estimular o desenvolvimento de informações, seja de
              pesquisa básica ou aplicada, no que tange às tecnologias de
              Microrredes;
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Promover estudos e medidas que tenham por finalidade aperfeiçoar o
              desempenho empresarial de seus associados;
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Promover a divulgação aos seus associados dos resultados dos
              estudos e trabalhos que realizar;
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Realizar ações e prestar serviços voltadas à educação, cultura,
              ciência, tecnologia e de inovação, de forma a disseminar as
              tecnologias de Microrredes;
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Promover eventos, congressos, campanhas, cursos, seminários,
              palestras, oficinas, estágios, fóruns, assim como encontros sobre
              temas relacionados aos interesses de Microrredes, bem como, para a
              divulgação das suas atividades;
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Promover, reunir, realizar e divulgar periodicamente pesquisas e
              estatísticas com vistas à elaboração de estudos informativos e
              avaliação das tendências e dimensão de Microrredes; 
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Manter intercâmbio de caráter cultural e informativo com outras
              associações e entidades afins, no Brasil e no exterior,
              promovendo, quando for o caso, atividades conjuntas;
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <BulletIcon className={classes.icon} />
            </ListItemIcon>
            <div className={classes.listItemText}>
              Defender os interesses dos associados, respeitando sempre este
              documento e a Lei. 
            </div>
          </ListItem>
        </List>
      </div>
    </div>
  );
}
