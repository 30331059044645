import React from "react";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";

import imgPath from "assets/img/pti.jpg";
import AboutUsSection from "./Sections/AboutUsSection.js";
import ObjectivesSection from "./Sections/ObjectivesSection.js";
import ValuesSection from "./Sections/ValuesSection.js";

export default function AssocieSePage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection
        title="Sobre Nós"
        imgPath={imgPath}
        size="cover"
        position="center"
      />

      <AboutUsSection />

      <ObjectivesSection />

      <ValuesSection />

      <Footer />
    </div>
  );
}
