import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import CustomButton from "components/CustomButtons/CustomButton.js";
import HeaderModal from "components/HeaderModal/HeaderModal.js";
import Button from "@material-ui/core/Button";

import {
  isAuthenticated,
  removeToken,
  getUserActive
} from "../../common/security/token";
import styles from "./headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const handleClick = () => {
    removeToken();
  };

  const user = getUserActive();

  const classes = useStyles();
  const buttonFont = {};

  return (
    <>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <CustomButton
            href="/"
            buttonProps={{
              className: classes.navLink,
              style: buttonFont
            }}
          >
            Início
          </CustomButton>
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Microrredes"
            buttonProps={{
              className: classes.navLink,
              style: buttonFont
            }}
            dropdownList={[
              <Link to="/sobre-nos" className={classes.dropdownLink}>
                Sobre Nós
              </Link>,
              <Link to="/estrutura-interna" className={classes.dropdownLink}>
                Estrutura Interna
              </Link>
            ]}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Associe-se"
            buttonProps={{
              className: classes.navLink,
              style: buttonFont
            }}
            dropdownList={[
              <Link to="/associados" className={classes.dropdownLink}>
                Associados
              </Link>,
              <Link to="/associe-se" className={classes.dropdownLink}>
                Seja um associado
              </Link>,
              <Link to="/beneficios" className={classes.dropdownLink}>
                Benefícios
              </Link>
            ]}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Iniciativas"
            buttonProps={{
              className: classes.navLink,
              style: buttonFont
            }}
            dropdownList={[
              <Link to="/artigos" className={classes.dropdownLink}>
                Artigos
              </Link>,
              <Link to="/publicaçoes" className={classes.dropdownLink}>
                Publicações
              </Link>,
              <Link to="/projetos" className={classes.dropdownLink}>
                Projetos
              </Link>,
              <Link to="/mapa" className={classes.dropdownLink}>
                Mapa
              </Link>
            ]}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Notícias e Eventos"
            buttonProps={{
              className: classes.navLink,
              style: buttonFont
            }}
            dropdownList={[
              <Link to="/noticias" className={classes.dropdownLink}>
                Notícias
              </Link>,
              <Link to="/eventos" className={classes.dropdownLink}>
                Eventos
              </Link>
            ]}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomButton
            href="/contato"
            color="transparent"
            buttonProps={{
              className: classes.navLink,
              style: buttonFont
            }}
          >
            Contato
          </CustomButton>
        </ListItem>

        {isAuthenticated() ? (
          <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              buttonText={
                user.name
                  ? `Olá, ${user.name.split(" ", 1)}!`
                  : "Seja bem vindo!"
              }
              buttonProps={{
                className: classes.profilebutton,
                color: "primary",
                style: buttonFont
              }}
              dropdownList={[
                <Button
                  href="https://www.acs.microrredes.org.br"
                  className={classes.dropdownButton}
                >
                  Dashboard
                </Button>,
                <Link
                  to="/"
                  onClick={handleClick}
                  className={classes.dropdownLink}
                >
                  Sair
                </Link>
              ]}
            />
          </ListItem>
        ) : (
          <ListItem className={classes.listItem}>
            <HeaderModal
              buttonTitle="Entrar"
              buttonClasses={classes.registerNavLink}
              title="Entrar"
            />
          </ListItem>
        )}
      </List>
    </>
  );
}
