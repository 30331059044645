import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: "theme.spacing(1)",
    backgroundColor: "theme.palette.secondary.main"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "theme.spacing(1)"
  },
  submit: {
    margin: "theme.spacing(3, 0, 2)",
    backgroundColor: "#198a86"
  },
  alert: {
    width: "100%",
    marginTop: "10px"
  }
}));

export default useStyles;
