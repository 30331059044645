import React from "react";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import PartnersList from "./Sections/PartnersList.js";

import imgPath from "assets/img/solar-panels.png";

export default function AssocieSePage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection title="Associados" imgPath={imgPath} size="cover" />

      <PartnersList />

      <Footer />
    </div>
  );
}
