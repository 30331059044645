import React from "react";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import BenefitsSection from "./Sections/BenefitsSection";

import imgPath from "assets/img/solar-panels.png";

export default function BeneficiosPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection title="Benefícios" imgPath={imgPath} size="cover" />

      <BenefitsSection />

      <Footer />
    </div>
  );
}
