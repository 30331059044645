import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import App from "./App";

ReactGA.initialize("UA-170822087-1");

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();
