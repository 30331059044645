import React from "react";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import ProjectList from "./Sections/ProjectList";

import imgPath from "assets/img/project.jpg";

export default function ProjetosPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection
        title="Projetos"
        imgPath={imgPath}
        size="cover"
        position="center"
      />

      <ProjectList />

      <Footer />
    </div>
  );
}
