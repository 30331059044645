import React from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../Styles/aboutStyle";

const useStyles = makeStyles(styles);

export default function AboutUsSection() {
  const classes = useStyles();

  return (
    <>
      <div className={classNames(classes.container, classes.section)}>
        <h2>Associação Brasileira de Microrredes</h2>
        <p>
          Fundada no ano de 2020, a Associação Brasileira de Microrredes (ABMR),
          é pessoa jurídica de direito privado sem fins lucrativos e desenvolve
          pesquisas básica e aplicada de caráter científico e tecnológico. A
          ABMR coordena, representa e defende os interesses de seus associados
          quanto ao tema microrredes no Brasil.
        </p>
      </div>
    </>
  );
}
