import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";

import api from "../../../common/api/config/api";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, Container } from "@material-ui/core";

import ContactFormModal from "./ContactFormModal.js";

import styles from "../Styles/contactFormStyle.js";

const useStyles = makeStyles(styles);

export default function BannerSection() {
  const classes = useStyles();

  const [values, setValues] = useState();
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [result, setResult] = useState(null);

  useEffect(() => console.log(values), [values]);
  useEffect(() => console.log(result), [result]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeCaptcha = value => {
    setDisabled(false);
  };

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleClickOpen();
    sendMessage();
  };

  const sendMessage = async () => {
    try {
      const headers = {
        "Content-Type": "application/json"
      };
      const response = await api
        .post(
          "/contact",
          {
            name: values.nome,
            email: values.email,
            subject: values.assunto,
            message: values.mensagem
          },
          { headers }
        )
        .then(message => /*alert(message),*/ setResult("success"));

      console.log(response);
    } catch (error) {
      console.log(error);
      setResult("error");
    }
  };

  return (
    <div className={classNames(classes.background, classes.section)}>
      <h5>Tem alguma dúvida?</h5>
      <h2>Entre em contato</h2>
      <Container className={classes.formWrapper} component="main" maxWidth="sm">
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="nome"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="nome"
                label="Nome completo"
                className={classes.inputWrapper}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="email"
                label="E-mail"
                autoComplete="email"
                className={classes.inputWrapper}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="assunto"
                variant="outlined"
                onChange={handleChange}
                fullWidth
                id="assunto"
                label="Assunto"
                className={classes.inputWrapper}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="mensagem"
                variant="outlined"
                onChange={handleChange}
                required
                fullWidth
                id="mensagem"
                label="Mensagem"
                multiline={true}
                rows={5}
                className={classes.inputWrapper}
              />
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                sitekey="6LfghuIUAAAAAEHGKEj0PO1SfCzEURy9YcRQ0zQr"
                onChange={onChangeCaptcha}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={disabled}
          >
            Enviar
          </Button>
        </form>
      </Container>

      <ContactFormModal open={open} result={result} handleClose={handleClose} />
    </div>
  );
}
