import api from "./api";

const createHeaders = auth => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  if (auth !== undefined && auth !== "") {
    return {
      ...headers,
      Authorization: `Bearer ${auth}`
    };
  }

  return headers;
};

const configHeaders = auth => ({
  headers: createHeaders(auth)
});

export const get = (path, auth) => {
  return api.get(`${path}`, configHeaders(auth));
};

export const post = (path, params, auth) => {
  return api.post(`${path}`, params, configHeaders(auth));
};

export const put = (path, params, auth) => {
  return api.put(`${path}`, params, configHeaders(auth));
};

export const patch = (path, params, auth) => {
  return api.patch(`${path}`, params, configHeaders(auth));
};

export const remove = (path, auth) => {
  return api.delete(`${path}`, configHeaders(auth));
};

export const handleRequestError = status => {
  if (status === 401 || status === 429 || status === 500) {
    return true;
  }
  return false;
};
