import { makeStyles } from "@material-ui/styles";

const ProjectStyle = makeStyles(() => ({
  projectsMap: {
    height: "calc(100vh - 96px)",
    margin: "96px 0 0",
    padding: 0,
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 959px)": {
      flexDirection: "column"
    }
  },
  menu: {
    padding: 20,
    height: "100%",
    borderRadius: "6px 0 0 6px",

    "@media (max-width: 959px)": {
      "& >div": {
        marginBottom: "10px"
      },
      "@media (max-width: 599px)": {
        width: "100%"
      }
    }
  },
  title: {
    color: "#263238",
    fontSize: 14,
    fontWeight: 500
  },
  checkbox: {
    height: "35px",
    "& span": {
      color: "#108a86"
    }
  },
  subtitleContainer: {
    position: "absolute",
    right: 0,
    bottom: 185,
    backgroundColor: "white",
    padding: 12,
    height: "fit-content",
    "@media (max-width: 959px)": {
      top: 110,
      bottom: 315
    },
    "@media (max-width: 599px)": {
      display: "none"
    }
  },
  alert: {
    position: "absolute",
    zIndex: "100",
    top: "100",
    width: "100%"
  }
}));

export default ProjectStyle;
