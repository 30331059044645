import { container } from "assets/jss/material-kit-react.js";

const categoriesStyle = theme => ({
  container,
  section: {
    padding: "30px 20px 90px",

    "& h1": {
      margin: "10px 0 0 0",
      fontWeight: 800,
      textTransform: "uppercase",
      fontFamily: "Open Sans, sans-serif"
    },

    "& h2": {
      marginBottom: 50,
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif"
    },

    "& h3": {
      marginTop: 30,
      marginBottom: 15,
      fontWeight: 500,
      fontFamily: "Open Sans, sans-serif"
    },

    "& p, & li": {
      fontSize: 14,
      lineHeight: 1.2
    }
  },
  background: {
    background: "#f5f5f5",
    paddingLeft: 15
  }
});

export default categoriesStyle;
