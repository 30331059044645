import React from "react";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import PublicaçoesList from "./Sections/PublicaçoesList.js";
import PublicationCard from "./Sections/PublicationCard.js";

import imgPath from "assets/img/publicacoes.jpg";

export default function PublicaçoesPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection
        title="Publicações"
        imgPath={imgPath}
        size="cover"
        position="center"
      />

      <PublicationCard />
      <PublicaçoesList />

      <Footer />
    </div>
  );
}
