import React from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../Styles/groupStyle";

const useStyles = makeStyles(styles);

export default function GroupSection() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section, classes.background)}>
      <div className={classes.container}>
        <h2>Grupos de Trabalho</h2>
        <p>
          Nossos Grupos de Trabalho - GT definem as diretrizes e propostas da
          ABMR para o mercado e o setor.
          <ul>
            <li>
              <span style={{ fontWeight: "bold" }}>
                GT1 - Tecnologias e Padrões:
              </span>{" "}
              tem como foco influenciar o desenvolvimento e a adoção de padrões
              industriais e arquiteturas de referência para o avanço das
              Microrredes, promovendo a integração de Microrredes no Brasil.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>
                GT2 - Modelos de Negócio:
              </span>{" "}
              objetiva estimular novos projetos e comunicar os casos de sucesso
              existentes, ilustrando os requisitos técnicos e comerciais
              necessários para monetizar e obter valor em diferentes segmentos
              de mercado.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>
                GT3 - Pesquisa e Conhecimento:
              </span>{" "}
              busca criar massa crítica, disseminar o conhecimento, atuar como
              facilitadores, criar oportunidades de networking, além de envolver
              e influenciar os principais interessados na temática Microrredes.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>
                GT4 - Política e Regulamentação:
              </span>{" "}
              tem como foco impulsionar políticas para a incorporação das
              Microrredes à rede, fornecendo mecanismos legais para o
              entendimento e a busca por oportunidades de negócios na temática.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>
                GT5 - Gestão e Articulação Institucional
              </span>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}
