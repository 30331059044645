import axios from 'axios';

export const populateUFs = () => {
  const data = axios
    .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    .then(response => {
      const states = response.data;
      states.sort((a, b) => (a.nome > b.nome ? 1 : -1));
      return states;
    });
  return data;
};

export const getCities = value => {
  const ufValue = value;
  const data = axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufValue}/municipios`,
    )
    .then(response => {
      const cities = response.data;
      cities.sort((a, b) => (a.nome > b.nome ? 1 : -1));
      return cities;
    });
  return data;
};
