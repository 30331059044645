import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import api from "../../../common/api/config/api";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Toolbar,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import styles from "../Styles/articlesListStyle";

const useStyles = makeStyles(styles);

// A partir de um array de objetos,
// retorna um novo array apenas com uma chave do objeto,
// pulando as repetições de valores dessa chave
function removeDuplicateKeys(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i][prop]);
  }
  return newArray;
}

export default function ArticlesList() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [articles, setArticles] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f8f8f8"
      }
    }
  }))(TableRow);

  const columns = [
    { id: "title", label: "Título", width: "70%" },
    {
      id: "authors",
      label: "Autor(es)",
      width: "30%",
      minWidth: 200
    }
  ];

  useEffect(() => {
    const fetchArticles = async () => {
      await api
        .get(`/users/{id}/articles`)
        .then(response => response.json())
        .then(data => {
          setArticles(data.result);
          setRows(data.result);
        });
    };
    fetchArticles();
  }, []);

  useEffect(() => {
    setAuthors(removeDuplicateKeys(articles, "authors"));
  }, [articles]);

  useEffect(() => {
    console.log(authors);
  }, [authors]);

  const handleChange = (event, value) => {
    console.log(value);
    if (value !== null) {
      if (typeof value === "object") {
        let auxArray = articles.filter(article =>
          article.title.includes(value.title)
        );
        setRows(auxArray);
      } else {
        let auxArray = articles.filter(article =>
          article.authors.includes(value)
        );
        setRows(auxArray);
      }
    } else {
      setRows(articles);
    }
  };

  // const handleChange = event => {
  //   console.log(event.target);
  //   if (event.target.id === "titulo") {
  //     console.log(`título: ${event.target.value}`);

  //     var PATTERN = event.target.value;
  //     var filtered = articles.filter(function(str) {
  //       return PATTERN.test(str);
  //     });
  //     setRows(filtered);
  //   } else if (event.target.id === "autor") {
  //     console.log(`autor: ${event.target.value}`);
  //   }
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classNames(classes.container, classes.section)}>
      <Toolbar className={classes.searchBar}>
        <Grid container>
          <Grid item xs={12} style={{ marginLeft: 24 }}>
            <h6 className={classes.title}>Procurar por</h6>
          </Grid>
          <Grid item xs={12} md={7} className={classes.search}>
            <Autocomplete
              id="titulo"
              options={articles}
              autoComplete={true}
              onChange={handleChange}
              getOptionLabel={option => option.title}
              style={{ minWidth: 300, width: "100%" }}
              noOptionsText="Não encontrado"
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{
                    root: classes.inputRoot
                  }}
                  label="Título"
                  variant="outlined"
                />
              )}
            />
            {/* <TextField
              id="titulo"
              style={{ minWidth: 300, width: "100%" }}
              onChange={handleChange}
              label="Título"
              type="search"
              variant="outlined"
            /> */}
          </Grid>
          <Grid item xs={12} md={4} className={classes.search}>
            <Autocomplete
              id="autor"
              options={authors}
              autoComplete={true}
              onInputChange={handleChange}
              style={{ minWidth: 300, width: "100%" }}
              noOptionsText="Não encontrado"
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{
                    root: classes.inputRoot
                  }}
                  label="Autor"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper className={classes.root}>
        <TableContainer
          className={classes.container}
          style={{ padding: 0, borderRadius: "4px 4px 0 0" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, width: column.width }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.id === "title" ? (
                              <a
                                href={row.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {value}
                              </a>
                            ) : (
                              value
                            )}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to}` +
            " de " +
            (count !== -1 ? count : `mais de ${to}`)
          }
          labelRowsPerPage="Itens por página"
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
