import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    textAlign: "left",
    minHeight: "80px"
  },
  description: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    textAlign: "left",
    minHeight: "65px"
  },
  imageContainer: {
    height: 150,
    width: "100%",
    margin: "0 auto 20px",
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    width: "100%",
    height: "auto"
  },
  statsItem: {
    display: "flex",
    alignItems: "center"
  },
  statsIcon: {
    color: "#444",
    marginRight: "8px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px"
  },
  button: {
    backgroundColor: "#108a86",
    borderRadius: "4px",
    padding: "5px 20px",
    color: "white",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "400",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#0d726f",
      color: "white"
    }
  }
}));

export default useStyles;
