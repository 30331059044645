import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";

import NewsletterModal from "./NewsletterModal.js";

import styles from "../Styles/newsletterStyle.js";

const useStyles = makeStyles(styles);

export default function NewsletterSection() {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState();
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [result, setResult] = useState(null);

  const handleChange = event => {
    setEmail(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => console.log(email), [email]);
  // const smtpJS = async message => {
  //   try {
  //     let body = `
  //     <h4>E-mail: ${email}</h4>`;

  //     await Email.send({
  //       SecureToken: "45f5f29a-5509-4217-a037-6ce22e27ea7b",
  //       To: "contato@microrredes.org.br",
  //       From: "no-reply@microrredes.org.br",
  //       Subject: "[Microrredes] Inscrição na Newsletter",
  //       Body: body
  //     }).then(message => /*alert(message),*/ setResult("success"));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleSubmit = e => {
    e.preventDefault();
    handleClickOpen();
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classNames(classes.section, classes.container)}
      >
        <Grid item xs={12} md={4}>
          <h2>Inscreva-se</h2>
        </Grid>
        <Grid item xs={12} md={8}>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <input
                  type="email"
                  name="EMAIL"
                  onChange={handleChange}
                  id="mce-EMAIL"
                  placeholder="Digite seu e-mail"
                  required
                  className={classNames(classes.input, classes.inputText)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <input
                  type="submit"
                  value="Receber informações"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className={classNames(classes.submitButton, classes.input)}
                  disabled
                />
              </Grid>
            </Grid>
          </form>
        </Grid>

        <NewsletterModal
          open={open}
          result={result}
          handleClose={handleClose}
        />
      </Grid>
    </div>
  );
}
