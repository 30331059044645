import React from "react";
import PropTypes from "prop-types";
import { isAuthenticated } from "./token";
import { Route } from "react-router-dom";
import ButtonDialog from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const PrivateRoute = ({ component: Component, ...props }) => {
  const { path } = props;
  return (
    <Route
      path={path}
      render={() =>
        isAuthenticated() ? (
          <Component component {...props} />
        ) : (
          <>
            <Dialog
              open="true"
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Área exclusiva para associados."}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Caso deseja acessar este conteúdo, você pode se tornar um
                  associado preenchendo o formulário de cadastro na página{" "}
                  <a href="/associe-se">Seja um associado</a>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ButtonDialog href="/" color="primary" autoFocus>
                  Fechar
                </ButtonDialog>
                <ButtonDialog href="/associe-se" color="primary" autoFocus>
                  Ir para a página
                </ButtonDialog>
              </DialogActions>
            </Dialog>
          </>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
};

export default PrivateRoute;
