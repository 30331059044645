import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    flexBasis: "16.66%",
    border: 0,
    padding: "8px 5px 0 5px"
  }
}))(TableCell);

export const StyledTableSubCell = withStyles(() => ({
  body: {
    padding: "0 5px 20px 5px",
    fontSize: 12,
    flexBasis: "15%",
    border: 0
  }
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export const StyledTableHeader = withStyles(theme => ({
  root: {
    display: "flex",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    },
    "& div": {
      fontWeight: 600,
      padding: "22px",
      backgroundColor: "#108a86",
      color: theme.palette.common.white,
      fontSize: 16,
      flexBasis: "13.5%"
    }
  }
}))(TableRow);

export const useStyle = makeStyles(() => ({
  container: {
    margin: "50px"
  },
  table: {
    minWidth: 650
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& h3": {
      color: "#999",
      fontWeight: 500
    }
  },
  details: {
    padding: "10px 20px",
    backgroundColor: "#eeefef"
  },
  borderActive: {
    borderLeft: "10px solid #72b545"
  }
}));
