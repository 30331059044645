import JwtDecode from "jwt-decode";
import Cookies from "js-cookie";

export const TOKEN_KEY = "acs-web-token";

export const isAuthenticated = () => {
  if (
    Cookies.get(TOKEN_KEY) === null ||
    typeof Cookies.get(TOKEN_KEY) === "undefined"
  ) {
    return false;
  }
  return true;
};

export const isExpired = () => {
  if (isAuthenticated()) {
    const { exp } = JwtDecode(Cookies.get(TOKEN_KEY));
    if (exp < (new Date().getTime() + 1) / 1000) {
      return true;
    }
  }
  return false;
};

export const removeToken = () => {
  Cookies.remove(TOKEN_KEY);
};

export const getToken = () => {
  if (!isExpired()) {
    return Cookies.get(TOKEN_KEY);
  }
  removeToken();
  return 0;
};

export const setToken = token => {
  Cookies.set(TOKEN_KEY, token);
};

export const getAuthorities = () => {
  if (isAuthenticated()) {
    const decoded = JwtDecode(getToken());
    return decoded.roles;
  }
  return 0;
};

export const isAdmin = () => {
  const authorities = getAuthorities().filter(
    role => role.authority === "ADMIN"
  );
  return authorities.length > 0;
};

export const getUserActive = () => {
  if (isAuthenticated()) {
    const decoded = JwtDecode(getToken());
    return decoded;
  }
  return false;
};
