/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";
// import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";

import { Typography, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./Styles/noticiaStyle";
import { newsList } from "../../assets/newsList";
import { useParams } from "react-router-dom";

// import imgPath from "assets/img/solar-panels-2.jpg";s

const NoticiaPage = () => {
  const classes = useStyles();
  const params = useParams();
  const [news] = useState(newsList);
  const [selectedNew, setsSelectedNew] = useState({});

  useEffect(() => {
    setsSelectedNew(news[params.id - 1]);
  }, [params.id, news]);

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" />
      {/* <BannerSection
        title="Notícias"
        imgPath={imgPath}
        size="cover"
        position="center"
      /> */}
      <div className={classes.container}>
        <div className={classes.section}>
          <Button href="/noticias" className={classes.backButton}>
            <ArrowBackIcon /> Voltar para Notícias
          </Button>
          <Typography component="h2" className={classes.title}>
            {selectedNew.title}
          </Typography>
          <Typography
            component="h4"
            className={classes.description}
            color="textSecondary"
            paragraph
          >
            {selectedNew.description}
          </Typography>
          <Typography className={classes.date} color="textSecondary">
            Publicado em {selectedNew.publishedAt}
          </Typography>
          {selectedNew.img ? (
            <div
              style={{ backgroundImage: `url(${selectedNew.img})` }}
              className={classes.img}
            ></div>
          ) : null}
          {selectedNew.content?.split("\n\n").map(s => {
            return <Typography className={classes.content}>{s}</Typography>;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NoticiaPage;
