import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import PDF from "../../../assets/articles/article01.pdf";
import IMG from "../../../assets/articles/article01.png";

const useStyles = makeStyles({
  card: {
    maxWidth: 480,
    display: "flex",
    margin: "50px auto 0"
  },
  cardContent: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  description: {
    fontSize: "14px",
    marginTop: "15px"
  },
  title: {
    fontSize: "25px",
    lineHeight: "27px",
    fontWeight: "600"
  },
  autor: {
    fontSize: "13px"
  },
  button: {
    backgroundColor: "#108a86",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#0d726f"
    },
    "& a": {
      color: "#ffffff"
    }
  }
});

function Articles() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          width="250"
          image={IMG}
          title="Contemplative Reptile"
        />
      </CardActionArea>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title}>
          O Mercado de Microrredes
        </Typography>
        <Typography className={classes.autor}>
          Alexandre R. Aoki, Rodrigo B. Otto
        </Typography>
        <Typography color="textSecondary" className={classes.description}>
          Desenvolvimento sustentável, eletrificação de áreas sem acesso,
          expansibilidade, agricultura sustentável, energia limpa e acessível e
          comunidades sustentáveis.
        </Typography>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
          >
            <a href={PDF} download="O_Mercado_de_Microrredes">
              Baixar Artigo
            </a>
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default Articles;
