/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import PropTypes from "prop-types";

function MarkerWithInfoWindow(props) {
  const { resource, position } = props;
  const [open, setOpen] = useState(false);

  function handleMarkerOpen() {
    setOpen(!open);
  }

  const selectIcon = recurso => {
    if (recurso === "Fotovoltáico") {
      return "#108a86";
    }
    if (recurso === "Geração a Diesel") {
      return "#407406";
    }
    if (recurso === "Eólica") {
      return "#ffbb00";
    }
    if (recurso === "Célula de Combustível") {
      return "#0c2bb6";
    }
    if (recurso === "Biogás") {
      return "#6b05be";
    }
    if (recurso === "Bateria de Lítio") {
      return "#29b931";
    }
    if (recurso === "Bateria de Chumbo") {
      return "#738cff";
    }
    if (recurso === "Bateria de Sódio") {
      return "#13c8ff";
    }
    if (recurso === "Bateria Outras") {
      return "#ff4f19";
    }
    if (recurso === "Hidrogênio") {
      return "#da2c57";
    }
    if (recurso === "Marémotriz") {
      return "#ad1d1d";
    }
    if (recurso === "Veículo Elétrico V2G") {
      return "#c57000";
    }
    if (recurso === "Outros") {
      return "#295bb9";
    }
    return null;
  };

  return (
    <Marker
      onClick={handleMarkerOpen}
      position={position}
      icon={{
        path:
          "M27.648 -41.399q0 -3.816 -2.7 -6.516t-6.516 -2.7 -6.516 2.7 -2.7 6.516 2.7 6.516 6.516 2.7 6.516 -2.7 2.7 -6.516zm9.216 0q0 3.924 -1.188 6.444l-13.104 27.864q-0.576 1.188 -1.71 1.872t-2.43 0.684 -2.43 -0.684 -1.674 -1.872l-13.14 -27.864q-1.188 -2.52 -1.188 -6.444 0 -7.632 5.4 -13.032t13.032 -5.4 13.032 5.4 5.4 13.032z",
        scale: 0.6,
        strokeWeight: 0.2,
        strokeColor: selectIcon(resource.name),
        strokeOpacity: 1,
        fillColor: selectIcon(resource.name),
        fillOpacity: 1,
        anchor: { x: 20, y: 0 }
      }}
    >
      {open && (
        <InfoWindow options={{ disableAutoPan: true }}>
          <div style={{ maxWidth: "400px" }}>
            <p>
              <strong>Projeto: </strong>
              {resource.title}
            </p>
            {resource.city ? (
              <p>
                <strong>Cidade: </strong>
                {resource.city}
              </p>
            ) : null}
            <p>
              <strong>Estado: </strong>
              {resource.uf}
            </p>
            <p>
              <strong>Recurso Energético: </strong>
              {resource.name}
            </p>
            <p>
              <strong>Potência: </strong>
              {`${resource.power}MW`}
            </p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}

MarkerWithInfoWindow.propTypes = {
  resource: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    city: PropTypes.string,
    uf: PropTypes.string,
    power: PropTypes.string
  }),
  position: PropTypes.shape({})
};

function Map(props) {
  const { resourcesShowed } = props;
  return (
    <>
      <GoogleMap
        zoom={4}
        center={{
          lat: -14,
          lng: -55
        }}
        mapContainerStyle={{ height: "100%", borderRadius: "0 6px 6px 0" }}
      >
        {resourcesShowed?.map(resource => (
          <MarkerWithInfoWindow
            key={`${resource.id}-${resource.title}`}
            resource={resource}
            isOpen
            position={{
              lat: parseFloat(resource.lat),
              lng: parseFloat(resource.lng)
            }}
          />
        ))}
      </GoogleMap>
    </>
  );
}

Map.propTypes = {
  resourcesShowed: PropTypes.arrayOf(PropTypes.shape({}))
};

export default Map;
