import React from "react";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";
import NewsFeedSection from "./Sections/NewsFeedSection";
import imgPath from "assets/img/solar-panels-2.jpg";

export default function NoticiasPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection
        title="Notícias"
        imgPath={imgPath}
        size="cover"
        position="center"
      />

      <NewsFeedSection />

      <Footer />
    </div>
  );
}
