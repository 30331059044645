import { container } from "assets/jss/material-kit-react.js";

const formStyle = theme => ({
  container,
  section: {
    padding: "50px 20px 40px",

    "& h2": {
      color: "#081624",
      fontWeight: 600,
      marginTop: 0,
      textAlign: "center"
    },
    "& h4": {
      fontFamily: "Roboto",
      color: "#AAAAAA",
      fontSize: 16,
      fontWeight: 400,
      margin: "0 0 5px 32px",
      textAlign: "left"
    },
    "& h5": {
      color: "#718090",
      fontSize: 18,
      lineHeight: 1,
      marginBottom: 5,
      textAlign: "center"
    }
  },
  formWrapper: {
    padding: "30px 0 0",
    "& input": {
      backgroundColor: "#fff"
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#198a86"
  },
  inputTitle: {
    fontFamily: "Roboto, sans-serif",
    color: "#7d7d84",
    fontWeight: 400,
    marginLeft: 15,
    fontSize: 16,
    textTransform: "none"
  },
  background: {
    background: "#EEEEEE"
  }
});

export default formStyle;
