import React from "react";
import classNames from "classnames";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ContactForm from "./ContactForm";

import MapMarker from "assets/img/map-marker.png";
import Phone from "assets/img/phone.png";
import Email from "assets/img/email.png";

import styles from "../Styles/contactInfoStyle";

const useStyles = makeStyles(styles);

export default function ContactInfoSection() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, classes.section)}>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.infoWrapper}>
          <h2 style={{ fontWeight: 500 }}>
            Associação Brasileira de Microrredes
          </h2>
          <h4>CNPJ: 37.487.531/0001-60</h4>
          <div className={classes.infoBox}>
            <div className={classes.icon}>
              <img src={MapMarker} alt="" className="" />
            </div>
            <div className={classes.text}>
              <h3>Endereço</h3>
              <p>
                {/*Av. Tancredo Neves 6731*/}
                {/*<br />*/}
                {/*Caixa postal 2012 - ITAI*/}
                {/*<br />*/}
                Foz do Iguaçu, PR
              </p>
            </div>
          </div>
          <div className={classes.infoBox}>
            <div className={classes.icon}>
              <img src={Phone} alt="" className="" />
            </div>
            <div className={classes.text}>
              {/*<h3>Telefone</h3>*/}
              {/*<p>+55 (45) 3576-7113</p>*/}
              <h3>Whatsapp</h3>
              <p>+55 (45) 99119-8681</p>
            </div>
          </div>
          <div className={classes.infoBox}>
            <div className={classes.icon}>
              <img src={Email} alt="" className="" />
            </div>
            <div className={classes.text}>
              <h3>E-mail</h3>
              <p>contato@microrredes.org.br</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactForm />
        </Grid>
      </Grid>
    </div>
  );
}
