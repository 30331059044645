import { get, post, put, remove } from "../config/api_methods";
import { getToken } from "../../security/token";

const PROJECT_PATH = "projects";

export const saveProject = params => {
  const response = post(PROJECT_PATH, params, getToken());
  return response;
};

export const getProjects = () => {
  const response = get(PROJECT_PATH, getToken());
  return response;
};

export const getProjectById = id => {
  const response = get(`${PROJECT_PATH}${id}`, getToken());
  return response;
};

export const getPowerResources = () => {
  const response = get("/power-resources", getToken());
  return response;
};

// export const getPowers = () => {
//   const response = get('/powers', getToken());
//   return response;
// };

export const updateProjectById = params => {
  const response = put(`${PROJECT_PATH}${params.id}`, params, getToken());
  return response;
};

export const removeProjectById = id => {
  const response = remove(`${PROJECT_PATH}${id}`, getToken());
  return response;
};
