import { container } from "assets/jss/material-kit-react.js";

const aboutStyle = theme => ({
  container,
  section: {
    textAlign: "center",
    padding: "130px 0 80px",

    [theme.breakpoints.down("sm")]: {
      padding: "70px 15px"
    },

    "& h2": {
      color: "#081624",
      marginBottom: 40
    },

    "& p": {
      color: "#718090",
      fontSize: 18,
      lineHeight: 2
    }
  }
});

export default aboutStyle;
