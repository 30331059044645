/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Logo from "assets/img/microrredes-abmr-logo.png";
import MapMarker from "assets/img/map-marker.png";
import Phone from "assets/img/phone.png";

import styles from "./footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
    [classes.footerBackground]: true,
  });
  const footerContent = {
    width: '100%',
  }

  return (
    <>
    <footer className={footerClasses} style={footerContent}>
      
      <div className={classes.container}>
         <Grid container className={classes.contentPosition}>
          <Grid item lg={3} md={4} sm={6}>
            <img src={Logo} alt="" width="100%" style={{ marginBottom: 40 }}/>
            <p>
              Atuar como um canal de diálogo com a sociedade civil, instituições
              governamentais e atores locais
            </p>
          </Grid>
          <Grid item container lg={2} md={4} sm={6} className={classes.spacing} style={{flexDirection: "column"}}>
            <h2 className={classes.contactTitle}>Siga a ABMR</h2>
            <div className={classes.socialWrapper}>
              <a className={classes.a} href="https://www.youtube.com/c/AssociacaoBrasileiradeMicrorredesABMR" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube fa-2x" aria-hidden="true"></i>
              </a>
              <a className={classes.a} href="https://www.facebook.com/AssociacaoBrasileiradeMicrorredesABMR" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f fa-2x" aria-hidden="true"></i>
                </a>
              <a className={classes.a} href="https://instagram.com/abmr.microrredes" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram fa-2x" aria-hidden="true"></i>
                </a>
              <a className={classes.a} href="https://www.linkedin.com/company/associa%C3%A7%C3%A3o-brasileira-de-microrredes-abmr/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin-in fa-2x" aria-hidden="true"></i>
                </a>
              <a className={classes.a}
                 href="https://wa.me/5545991198681"
                 target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp fa-2x" aria-hidden="true"></i>
              </a>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} className={classes.spacing}>
            <h2 className={classes.contactTitle}>Entre em contato</h2>
            <div className={classes.footerInfoBox}>
              <div className={classes.FIBIcon}>
                <img src={MapMarker} alt="" className="" />
              </div>
              <div className={classes.FIBText}>
                {/*Av. Tancredo Neves 6731<br />Caixa postal 2012 - ITAI<br/>*/}
                <p>Foz do Iguaçu, PR</p>
              </div>
            </div>
            <div className={classes.footerInfoBox}>
              <div className={classes.FIBIcon}>
                <img src={Phone} alt="" className="" />
              </div>
              <div className={classes.FIBText}>
                <p>+55 (45) 99119-8681<br />contato@microrredes.org.br</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>

    <div className={classes.footerBottom}>
      
      <div className={classNames(classes.container)}>
        © {new Date().getFullYear()}. Associação Brasileira de Microrredes ‒ CNPJ: 37.487.531/0001-60
      </div>
    </div>
    </>
  );
}

Footer.propTypes = {
 whiteFont: PropTypes.bool
};
