import { fade } from "@material-ui/core/styles";

const partnerListStyle = theme => ({
  section: {
    padding: "60px 15px 90px",

    "& p": {
      color: "#718090",
      fontSize: 16
    }
  },
  title: {
    fontWeight: "bold",
    color: "#666",
    fontSize: 13,
    marginTop: 0
  },
  searchBar: {
    backgroundColor: "#eee",
    borderRadius: "6px",
    padding: "10px 0",
    marginBottom: 40
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: fade("#FFFFFF", 0.85),
    "&:hover": {
      backgroundColor: fade("#FFFFFF", 0.65)
    },
    marginRight: "16px",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "24px",
      width: "auto"
    }
  },
  searchIcon: {
    padding: "0 16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formControl: {
    width: "100%",
    backgroundColor: fade("#FFFFFF", 0.15),
    ".MuiSelect-outlined.MuiSelect-outlined, &:hover": {
      backgroundColor: fade("#FFFFFF", 0.15)
    }
  },
  inputRoot: {
    color: "#333"
  },
  inputInput: {
    padding: "8px 8px 8px 0",
    // vertical padding + font size from searchIcon
    paddingLeft: `32px`,
    transition: "width",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch"
    }
  },
  list: {
    alignItems: "stretch"
  },
  listItem: {
    display: "flex",
    flex: "1 1 100%",
    "& > div": {
      margin: 0
    }
  },
  imgCardTop: {
    padding: "20px 15px 10px",
    height: 200,
    flex: "0 0 auto",
    objectFit: "scale-down"
  },
  cardTitle: {
    color: "#444",
    fontWeight: 600,
    fontSize: 16,
    flexGrow: 1
  },
  contactInfo: {
    minHeight: 95,
    "& h6": {
      display: "flex"
    },
    "& svg": {
      marginRight: 10
    }
  },
  notFound: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 50,
    color: "#aaa"
  }
});

export default partnerListStyle;
