import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "./Sections/BannerSection.js";
import IntroductionSection from "./Sections/IntroductionSection.js";
import AboutSection from "./Sections/AboutSection.js";
import NewsAndEventsSection from "./Sections/NewsAndEventsSection.js";
import NewsletterSection from "./Sections/NewsletterSection.js";
import PartnersSection from "./Sections/PartnersSection.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const IntroductionWrapper = {
    maxWidth: 1200,
    marginLeft: "auto",
    marginRight: "auto"
  };

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection />

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={IntroductionWrapper}
      >
        <IntroductionSection />
      </div>

      <AboutSection />
      <PartnersSection />
      <NewsAndEventsSection />
      <NewsletterSection />

      <Footer />
    </div>
  );
}
