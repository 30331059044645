import { container } from "assets/jss/material-kit-react.js";

const tabsStyle = theme => ({
  section: {
    background: "#EEEEEE",
    padding: "70px 0",
    '& h3': {
      fontWeight: 500,
    }
  },
  container,
  textCenter: {
    textAlign: "center"
  },
  description: {
    paddingLeft: 15,
    paddingRight: 40,
    paddingBottom: 40,

    [theme.breakpoints.down("sm")]: {
      paddingRight: 15,

    }
  },
  list: {
    '&:hover,&:focus': {
      color: "108a86"
    }
  }
});

export default tabsStyle;
