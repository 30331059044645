import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./bannerStyle";

const useStyles = makeStyles(styles);

export default function InnerPageBanner(props) {
  const classes = useStyles();

  const { title, subtitle, imgPath, size, position } = props;

  return (
    <>
      <div
        style={{
          background: "url(" + imgPath + ")",
          backgroundPosition: position,
          backgroundSize: size
        }}
        className={classes.background}
      >
        <div className={classes.layer}>
          <div className={classes.container}>
            <h2>{title}</h2>
            {subtitle ? <h4>{subtitle}</h4> : ""}
          </div>
        </div>
      </div>
    </>
  );
}

InnerPageBanner.propTypes = {
  title: PropTypes.string,
  imgPath: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.string
};
