export const resources = [
  {
    name: "Fotovoltáico",
    type: "PHOTOVOLTAIC"
  },
  {
    name: "Geração a Diesel",
    type: "DIESEL_GENERATION"
  },
  {
    name: "Eólica",
    type: "WIND"
  },
  {
    name: "Biogás",
    type: "BIOGAS"
  },
  {
    name: "Bateria Lítio",
    type: "LITHIUM_BATTERY"
  },
  {
    name: "Bateria Chumbo",
    type: "LEAD_BATTERY"
  },
  {
    name: "Bateria Sódio",
    type: "SODIUM_BATTERY"
  },
  {
    name: "Bateria Outras",
    type: "BATTERY_OTHER"
  },
  {
    name: "Hidrogênio",
    type: "HYDROGEN"
  },
  {
    name: "Célula de Combustível",
    type: "FUEL_CELL"
  },
  {
    name: "Marémotriz",
    type: "TIDE_ENERGY"
  },
  {
    name: "Veículo Elétrico V2G",
    type: "ELECTRIC_VEHICLE_V2G"
  },
  {
    name: "Outros",
    type: "OTHERS"
  }
];

export const powerList = [
  {
    label: "0MW a 5MW",
    min: 0,
    max: 5
  },
  {
    label: "5MW a 10MW",
    min: 5,
    max: 10
  },
  {
    label: "10MW a 15MW",
    min: 10,
    max: 15
  },
  {
    label: "15MW a 20MW",
    min: 15,
    max: 20
  },
  {
    label: "20MW a 25MW",
    min: 20,
    max: 25
  },
  {
    label: "25MW a 30MW",
    min: 25,
    max: 30
  },
  {
    label: "30MW a 35MW",
    min: 30,
    max: 35
  },
  {
    label: "35MW a 40MW",
    min: 35,
    max: 40
  },
  {
    label: "40MW a 45MW",
    min: 40,
    max: 45
  },
  {
    label: "45MW a 50MW",
    min: 45,
    max: 50
  },
  {
    label: "acima de 50MW",
    min: 50,
    max: 900000
  }
];
