import React from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../Styles/transparencyStyle";

import estatuto from "../../../assets/ESTATUTO-ABMR-CARTORIO.pdf";

const useStyles = makeStyles(styles);

export default function TransparencySection() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section, classes.background)}>
      <div className={classes.container}>
        <h2>Transparência</h2>
        <p>
          Tenha acesso ao Estatuto da ABMR no link abaixo:
          <ul>
            <li>
              <a href={estatuto} target="_blank" rel="noopener noreferrer">
                PDF do Estatuto da ABMR
              </a>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}
