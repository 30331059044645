import { container } from "assets/jss/material-kit-react.js";

const bannerStyle = {
  container,
  section: {
    textAlign: "center",
    padding: "50px 0",

    "& h2": {
      color: "#081624",
      marginBottom: 40
    },

    "& p": {
      color: "#718090",
      fontSize: 18,
      lineHeight: 2
    }
  },
  background: {
    marginTop: 86,
    height: 250,
    position: "relative",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.5), 0 7px 10px -5px rgba(0, 0, 0, 0.5)"
  },
  layer: {
    background:
      "linear-gradient(40deg, rgba(0, 0, 0, 0.6) 30%, transparent 70%)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",

    "& h2": {
      color: "#fff",
      fontWeight: 700,
      fontSize: 50,
      letterSpacing: 3
    }
  }
};

export default bannerStyle;
