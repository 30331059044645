import { makeStyles } from "@material-ui/styles";

const newsFeedStyle = makeStyles(() => ({
  section: {
    padding: "50px 20px",
    boxSizing: "border-box",
    justifyContent: "center",

    "& p": {
      color: "#494949"
    }
  },
  cardWrapper: {
    paddingBottom: 40,
    "@media and screen (max-width: 920px)": {
      paddingleft: 30,
      paddingRight: 30
    }
  },
  card: {
    display: "flex",
    "@media and screen (min-width: 920px)": {
      display: "block"
    }
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    paddingLeft: "24px"
  },
  content: {
    flex: "1 0 auto"
  },
  media: {
    flex: "1 0 auto",

    maxWidth: 500,
    maxHeight: 350,

    "@media and screen (min-width: 920px)": {
      flex: "unset",
      maxWidth: "unset",
      maxHeight: "unset"
    }
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "50px"
  }
}));

export default newsFeedStyle;
