import React, { Component, Fragment } from "react";
import isEmpty from "lodash.isempty";

import GoogleMap from "./GoogleMap";

const getInfoWindowString = place => `
    <div>
      <div style="font-size: 16px; margin-bottom: 5px">
        ${place.name}
      </div>
      <div style="font-size: 14px; margin-bottom: 2px;">
        <span style="color: grey;">
        ${place.rating}
        </span>
        <span style="color: orange;">${String.fromCharCode(9733).repeat(
          Math.floor(place.rating)
        )}</span><span style="color: lightgrey;">${String.fromCharCode(
  9733
).repeat(5 - Math.floor(place.rating))}</span>
      </div>
      <div style="font-size: 14px;">
      ${place.vicinity}
      </div>
      <div style="margin-top: 3px; font-size: 13px; font-family: Roboto,Arial; color: #427fed">
        <a href="${
          place.url
        }" target="_blank" rel="noopener noreferrer">Visualize no Google Maps</a> 
      </div>
    </div>`;

const handleApiLoaded = (map, maps, place) => {
  const marker = new maps.Marker({
    position: {
      lat: place.geometry.location.lat,
      lng: place.geometry.location.lng
    },
    map
  });

  const infowindow = new maps.InfoWindow({
    content: getInfoWindowString(place)
  });

  infowindow.open(map, marker);

  map.addListener("click", () => {
    infowindow.close();
  });

  marker.addListener("click", () => {
    infowindow.open(map, marker);
  });
};

const createMapOptions = maps => {
  return {
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true
  };
};

class MapSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      place: null
    };
  }

  componentDidMount() {
    fetch("places.json")
      .then(response => response.json())
      .then(data => {
        this.setState({ place: data.result });
      });
  }

  render() {
    const { place } = this.state;

    return (
      <Fragment>
        {!isEmpty(place) && (
          <GoogleMap
            defaultZoom={17}
            defaultCenter={[-25.4357057, -54.5949396]}
            options={createMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) =>
              handleApiLoaded(map, maps, place)
            }
          />
        )}
      </Fragment>
    );
  }
}

export default MapSection;
