const customEventStyle = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    "& h6": {
      textTransform: "none"
    }
  },
  wrapper: {
    marginTop: 20
  }
});

export default customEventStyle;
