import { container } from "assets/jss/material-kit-react.js";

const benefitsStyle = theme => ({
  container,
  section: {
    padding: "50px 20px",
    background: "#f5f5f5",

    "& h2": {
      color: "#081624",
      marginBottom: 30,
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif"
    },

    "& p, & li": {
      color: "#666",
      fontSize: 17.5,
      lineHeight: 2
    }
  },
  icon: {
    fill: "#198a86",
    zIndex: "90",
    marginTop: 7
  },
  listItem: {
    color: "#333",
    fontSize: 17,
    fontWeight: 600,
    paddingLeft: 0,
    paddingRight: 10,
    paddingBottom: 30,
    fontFamily: "Open Sans, sans-serif",
    alignItems: "flex-start",

    "&:before": {
      content: "''",
      position: "absolute",
      left: 11,
      top: 20,
      zIndex: "1",
      borderLeft: "1px solid #999",
      height: "100%",
      width: 1
    },

    "&:last-child:before": {
      height: 6
    },

    "&:hover": {
      "& div": {
        color: "#444"
      },

      "& svg": {
        fill: "#000"
      }
    }
  }
});

export default benefitsStyle;
