import React from "react";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import BannerSection from "../../components/InnerPageBanner/InnerPageBanner.js";

import imgPath from "assets/img/pti.jpg";

import OrganizationGraph from "./Sections/OrganizationGraph";
import GroupSection from "./Sections/GroupSection";
import TransparencySection from "./Sections/TransparencySection";
// import CompositionSection from "./Sections/CompositionSection";

export default function EstruturaInternaPage(props) {
  const { ...rest } = props;

  return (
    <div>
      <Header rightLinks={<HeaderLinks />} fixed color="white" {...rest} />

      <BannerSection
        title="Estrutura Interna"
        imgPath={imgPath}
        size="cover"
        position="center"
      />

      <OrganizationGraph />
      <GroupSection />
      <TransparencySection />
      {/* <CompositionSection /> */}

      <Footer />
    </div>
  );
}
