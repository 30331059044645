import { container } from "assets/jss/material-kit-react.js";

const benefitsStyle = theme => ({
  container,
  section: {
    padding: "50px 20px",

    "& h2": {
      color: "#081624",
      marginBottom: 30,
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif",
    },

    "& h3": {
      color: "#081624",
      marginTop: 30,
      marginBottom: 15,
      fontWeight: 500,
      fontFamily: "Open Sans, sans-serif",
    },


    "& p, & li": {
      color: "#666",
      fontSize: 16,
      lineHeight: 1.5
    },
  },
  icon: {
    color: "#198a86",
    marginRight: 15,
    marginBottom: 10
  },
  listItem: {
    flexWrap: "wrap",
    marginBottom: 10
  },
  listItemHeading: {
    width: "100%"
  },
  listItemTitle: {
    color: "#333",
    fontSize: 17,
    fontWeight: 600,
    paddingRight: 10,
    fontFamily: "Open Sans, sans-serif",
  },
});


export default benefitsStyle;
